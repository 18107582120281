import { Component, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { IonSearchbar, ModalController, NavController, IonInfiniteScroll } from "@ionic/angular";
import Action from 'src/app/models/action';
import Contact from 'src/app/models/contact';
import Note from 'src/app/models/note';
import Actionpath from 'src/app/models/actionpath';
import { ActionService } from 'src/app/services/action.service';
import { ActionpathService } from 'src/app/services/actionpath.service';
import { ContactService } from 'src/app/services/contact.service';
import { NoteService } from 'src/app/services/note.service';
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from 'src/app/services/global.service';
import { ContactGroup } from "src/app/models/contact-group";

enum ShowMoreDataType {
  Contacts,
  Actions,
  Actionpaths,
  Touchpoints,
  Groups,
  Notes
}

@Component({
  selector: "app-search",
  templateUrl: "./search.page.html",
  styleUrls: ["./search.page.scss"],
})
export class SearchPage implements OnInit {
  @ViewChild("searchInput") searchInput: any;

  public isLoading: boolean = true;
  public searchString: string = "";

  public type: string;
  public currentSlice: number[] = [10, 10, 10, 10, 10];
  public startingSlice: number = 10;
  public sliceSize: number = 10;

  private contacts: Contact[] = [];
  private groups: ContactGroup[] = [];
  private actions: Action[] = [];
  private notes: Note[] = [];
  private touchpoints: Action[] = [];
  private actionpaths: Actionpath[] = [];

  public showContacts: Contact[] = [];
  public showGroups: ContactGroup[] = [];
  public showActions: Contact[] = [];
  public showNotes: Contact[] = [];
  public showTouchpoints: Contact[] = [];
  public showActionpaths: Contact[] = [];

  public filteredContacts: Contact[] = [];
  public filteredGroups: ContactGroup[] = [];
  public filteredActions: Action[] = [];
  public filteredNotes: Note[];
  public filteredTouchpoints: Action[];
  public filteredActionpaths: Actionpath[];
  public showMoreDataType: ShowMoreDataType;
  public showLessDataType: ShowMoreDataType;

  public shownSearchedContacts: Contact[] = [];

  public ShowMoreDataType: typeof ShowMoreDataType = ShowMoreDataType;
  public dataProgress = 0;
 
  constructor(
    private modalController: ModalController,
    private contactService: ContactService,
    private navController: NavController,
    private noteService: NoteService,
    private actionpathService: ActionpathService,
    private route: ActivatedRoute,
    private actionService: ActionService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        GlobalService.log(params.type);

        this.type = params.type;
    }});

    this.ionViewDidEnterManual();
  }

  async ionViewDidEnterManual() {
    GlobalService.log(this.type);

    if(this.type == "dashboard"){
      this.startingSlice = 3;
      this.currentSlice = [3, 3, 3, 3, 3, 3];
    }

    this.getData();
  }

  async openSearchbar(){    
    document.getElementById("searchInput").getElementsByTagName("div")[0].getElementsByTagName("input")[0].focus();
  }

  private async getData () {
    this.isLoading = true;
    this.dataProgress = 0;
    
    if(this.type == "contact" || this.type == "dashboard"){
      GlobalService.log("local list", this.contactService.LocalList);
      this.loadUntilNextProgress(20);
      this.contacts = this.contactService.LocalList;
      this.filteredContacts = this.contacts;
      this.showContacts = this.filteredContacts.slice(0,this.startingSlice);
    }
    
    if(this.type == "action" || this.type == "dashboard"){
      this.loadUntilNextProgress(50);
      this.actions = await this.actionService.getActionsByStatus(-1);
      this.filteredActions = this.actions;
      this.showActions = this.filteredActions.slice(0,this.startingSlice);
    }
    
    if(this.type == "note" || this.type == "dashboard"){
      this.loadUntilNextProgress(70);
      this.notes.push(...(await this.noteService.getPersonalNotes()));
      this.notes.push(...(await this.noteService.getContactNotes()));
      this.filteredNotes = this.notes;
      this.showNotes = this.filteredNotes.slice(0,this.startingSlice);
    }

    if(this.type == "touchpoint" || this.type == "dashboard"){
      this.loadUntilNextProgress(90);
      this.touchpoints = await this.actionService.getActionsByStatus(2);
      this.filteredTouchpoints = this.touchpoints;
      this.showTouchpoints = this.filteredTouchpoints.slice(0,this.startingSlice);
    }

    if(this.type == "actionpath" || this.type == "dashboard"){
      this.loadUntilNextProgress(95);
      this.actionpaths = await this.actionpathService.getAllactionpaths();
      this.filteredActionpaths = this.actionpaths;
      this.showActionpaths = this.filteredActionpaths.slice(0,this.startingSlice);
    }

    if(this.type == "group" || this.type == "dashboard"){
      this.loadUntilNextProgress(98);
      this.groups = await this.contactService.getAllGroups();
      this.filteredGroups = this.groups;
      this.showGroups = this.filteredGroups.slice(0,this.startingSlice);
      this.loadUntilNextProgress(100);
    }

    GlobalService.log(this.getGlobalSearch());
    if(this.getGlobalSearch() != ""){
      this.onSearch(this.getGlobalSearch());
    }

    this.isLoading = false;
    await GlobalService.sleep(100);
    this.openSearchbar();
  }

  public async loadUntilNextProgress(Maximum){
    while(this.dataProgress < Maximum){
      this.dataProgress++;
      await GlobalService.sleep(150)
    }
  }

  public dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  public async onSearch ($event, globalsearch = false) {
    GlobalService.log("on search triggered");
    let val;
    if(globalsearch){
      GlobalService.SearchData = $event;
      val = $event;
    } else {
      if($event.detail && $event.detail.value){
        GlobalService.SearchData = $event.detail.value;
        val = $event.detail.value;
      }
    }
    
    GlobalService.SearchData = val;
    this.searchString = val;

    if(this.searchString){
      if(this.type == "contact" || this.type == "dashboard"){
        await this.searchContacts(this.searchString);
        if(this.currentSlice[0] < this.startingSlice){
          this.currentSlice[0] = this.startingSlice;
        }
      }
  
      if(this.type == "action" || this.type == "dashboard"){
        await this.searchActions(this.searchString);
        if(this.currentSlice[1] < this.startingSlice){
          this.currentSlice[1] = this.startingSlice;
        }
      }
      
      if(this.type == "actionPath" || this.type == "dashboard"){
        await this.searchActionpaths(this.searchString);;
        if(this.currentSlice[2] < this.startingSlice){
          this.currentSlice[2] = this.startingSlice;
        }
      }
      
      if(this.type == "touchpoint" || this.type == "dashboard"){
        await this.searchTouchpoints(this.searchString);
        if(this.currentSlice[3] < this.startingSlice){
          this.currentSlice[3] = this.startingSlice;
        }
      }
      
      if(this.type == "note" || this.type == "dashboard"){
        await this.searchNotes(this.searchString);
        if(this.currentSlice[4] < this.startingSlice){
          this.currentSlice[4] = this.startingSlice;
        }
      }
      
      if(this.type == "group" || this.type == "dashboard"){
        await this.searchGroups(this.searchString);
        if(this.currentSlice[5] < this.startingSlice){
          this.currentSlice[5] = this.startingSlice;
        }
      }
      GlobalService.log(this.currentSlice);
    }
  }

  //showButton array items correlate with the enum items
  //showButton[0] --> Contacts, showButton[1] --> Actions etc.
  public getShownItems (dataType: ShowMoreDataType, increase: boolean) {
    if(dataType == ShowMoreDataType.Contacts){
      if(increase == false){
        this.currentSlice[0] = this.currentSlice[0]-this.sliceSize;
      }
      else{
        this.currentSlice[0] = this.currentSlice[0]+this.sliceSize;
      }
      this.showContacts = this.filteredContacts.slice(0,this.currentSlice[0]);
    }
    else if(dataType == ShowMoreDataType.Actions){
      if(increase == false){
        this.currentSlice[1] = this.currentSlice[1]-this.sliceSize;
      }
      else{
        this.currentSlice[1] = this.currentSlice[1]+this.sliceSize;
      }
      this.showActions = this.filteredActions.slice(0,this.currentSlice[1]);
    }
    else if(dataType == ShowMoreDataType.Actionpaths){
      if(increase == false){
        this.currentSlice[2] = this.currentSlice[2]-this.sliceSize;
      }
      else{
        this.currentSlice[2] = this.currentSlice[2]+this.sliceSize;
      }
      this.showActionpaths = this.filteredActionpaths.slice(0,this.currentSlice[2]);
    }
    else if(dataType == ShowMoreDataType.Touchpoints){
      if(increase == false){
        this.currentSlice[3] = this.currentSlice[3]-this.sliceSize;
      }
      else{
        this.currentSlice[3] = this.currentSlice[3]+this.sliceSize;
      }
      this.showTouchpoints = this.filteredTouchpoints.slice(0,this.currentSlice[3]);
    }
    else if(dataType == ShowMoreDataType.Notes){
      if(increase == false){
        this.currentSlice[4] =  this.currentSlice[4]-this.sliceSize;
      }
      else{
        this.currentSlice[4] = this.currentSlice[4]+this.sliceSize;
      }
      this.showNotes = this.filteredNotes.slice(0,this.currentSlice[4]);
    }  
    else if(dataType == ShowMoreDataType.Groups){
      if(increase == false){
        this.currentSlice[5] =  this.currentSlice[5]-this.sliceSize;
      }
      else{
        this.currentSlice[5] = this.currentSlice[5]+this.sliceSize;
      }
      this.showNotes = this.filteredNotes.slice(0,this.currentSlice[5]);
    }  
  }

  public async filterContactChunk (value, chunk: Contact[]) {
    GlobalService.log("filter value", value);
    GlobalService.log("filter chunk", chunk);

    this.filteredContacts.push(...chunk.filter(contact => {
      return contact.first_name?.toLowerCase().includes(value.toLowerCase()) || contact.last_name?.toLowerCase().includes(value.toLowerCase()) 
      || contact.company?.toLowerCase().includes(value.toLowerCase()) || contact.function?.toLowerCase().includes(value.toLowerCase());
    }))
  }

  public searchContacts(value) {
    GlobalService.log("search contacts", value);

    this.filteredContacts = [];

    var size = 10;

    GlobalService.log("this contacts", this.contacts);
    for (var i = 0; i < this.contacts.length; i += size) {
      let chunk = this.contacts.slice(i, Math.min(i + size, this.contacts.length));
      GlobalService.log("chunk", chunk);
      this.filterContactChunk(value, chunk);
    }

    if(this.filteredContacts != undefined){
      if(this.currentSlice[0] > this.filteredContacts.length){
        this.currentSlice[0] = this.filteredContacts.length;
      }
      this.showContacts = this.filteredContacts.slice(0,this.currentSlice[0]);
    } else {
      this.showContacts = this.filteredContacts.slice(0,this.startingSlice);
      this.currentSlice[0] = this.startingSlice;
    }

    return this.currentSlice[0];
  }

  public async filterActionChunk (value, chunk: Action[], i) {
    this.filteredActions.push(...chunk.filter(action => {
      return action.description?.toLowerCase().includes(value.toLowerCase()) 
      || action.title?.toLowerCase().includes(value.toLowerCase())
      || action.contacts && action.contacts.length > 0 
      && this.checkContactData(value, action)
    }));
  }

  public checkContactData(value, action){
    let result = action.contacts.filter(contact => {
      return (contact.first_name?.toLowerCase() + " " + contact.last_name?.toLowerCase()).includes(value.toLowerCase()) 
      || contact.company?.toLowerCase().includes(value.toLowerCase()) 
      || contact.function?.toLowerCase().includes(value.toLowerCase());
     });

    if(result.length > 0) return true;
    else return false;
  }

  public searchActions(value) {
    this.filteredActions = [];
    var size = 10;

    for (var i = 0; i < this.actions.length; i += size) {
      let chunk = this.actions.slice(i, Math.min(i + size, this.actions.length));
      this.filterActionChunk(value, chunk, i);
    }

    if(this.filteredActions != undefined){
      if(this.currentSlice[1] > this.filteredActions.length){
        this.currentSlice[1] = this.filteredActions.length;
      }
      this.showActions = this.filteredActions.slice(0,this.currentSlice[1]);
    } else {
      this.showActions = this.filteredActions.slice(0,this.startingSlice);
      this.currentSlice[1] = this.startingSlice;
    }
    return this.currentSlice[1];
  }

  public async filterActionpathChunk (value, chunk: Actionpath[], i) {
    this.filteredActionpaths.push(...chunk.filter(actionpath => {
      return actionpath.description?.toLowerCase().includes(value.toLowerCase()) || actionpath.title?.toLowerCase().includes(value.toLowerCase());
    }));

  }

  public searchActionpaths(value) {

    this.filteredActionpaths = [];

    var size = 10;

    for (var i = 0; i < this.actionpaths.length; i += size) {
      let chunk = this.actionpaths.slice(i, Math.min(i + size, this.actionpaths.length));
      this.filterActionpathChunk(value, chunk, i);
    }

    if(this.filteredActionpaths != undefined){
      if(this.currentSlice[2] > this.filteredActionpaths.length){
        this.currentSlice[2] = this.filteredActionpaths.length;
      }
      this.showActionpaths = this.filteredActionpaths.slice(0,this.currentSlice[2]);
      this.currentSlice[2] = this.startingSlice;
    }
    return this.currentSlice[2];
  }

  public async filterTouchpointChunk (value, chunk: Action[], i) {
    this.filteredTouchpoints.push(...chunk.filter(touchpoint => {
      return touchpoint.description?.toLowerCase().includes(value.toLowerCase()) 
      || touchpoint.title?.toLowerCase().includes(value.toLowerCase())
      || touchpoint.contacts && touchpoint.contacts.length > 0 
      && this.checkContactData(value, touchpoint);
    }));

  }

  public searchTouchpoints(value) {
    this.filteredTouchpoints = [];

    var size = 10;

    for (var i = 0; i < this.touchpoints.length; i += size) {
      let chunk = this.touchpoints.slice(i, Math.min(i + size, this.touchpoints.length));
      this.filterTouchpointChunk(value, chunk, i);
    }

    if(this.filteredTouchpoints != undefined){
      if(this.currentSlice[3] > this.filteredTouchpoints.length){
        this.currentSlice[3] = this.filteredTouchpoints.length;
      }
      this.showTouchpoints = this.filteredTouchpoints.slice(0,this.currentSlice[3]);
    } else {
      this.showTouchpoints = this.filteredTouchpoints.slice(0,this.startingSlice);
      this.currentSlice[3] = this.startingSlice;
    }
    return this.currentSlice[3];
  }

  public async filterNoteChunk (value, chunk: Note[], i) {
    this.filteredNotes.push(...chunk.filter(note => {
      return note.title?.toLowerCase().includes(value.toLowerCase()) || note.note?.toLowerCase().includes(value.toLowerCase());
    }));
  }

  public async filterGroupChunk (value, chunk: ContactGroup[], i) {
    this.filteredGroups.push(...chunk.filter(group => {
      return group.name?.toLowerCase().includes(value.toLowerCase());
    }));
  }

  public searchNotes(value) {
    this.filteredNotes = [];

    var size = 10;

    for (var i = 0; i < this.notes.length; i += size) {
      let chunk = this.notes.slice(i, Math.min(i + size, this.notes.length));
      this.filterNoteChunk(value, chunk, i);
    }

    if(this.filteredNotes != undefined){
      if(this.currentSlice[4] > this.filteredNotes.length){
        this.currentSlice[4] = this.filteredNotes.length;
      }
      this.showNotes = this.filteredNotes.slice(0,this.currentSlice[4]);
    } else {
      this.showNotes = this.filteredNotes.slice(0,this.startingSlice);
      this.currentSlice[4] = this.startingSlice;
    }
    return this.currentSlice[4];
  }

  public searchGroups(value){
    this.filteredGroups = [];

    var size = 10;

    for (var i = 0; i < this.groups.length; i += size) {
      let chunk = this.groups.slice(i, Math.min(i + size, this.groups.length));
      this.filterGroupChunk(value, chunk, i);
    }

    if(this.filteredGroups != undefined){
      console.log("filtered group", this.filteredGroups);
      if(this.currentSlice[5] > this.filteredGroups.length){
        this.currentSlice[5] = this.filteredGroups.length;
      }
      this.showGroups = this.filteredGroups.slice(0,this.currentSlice[5]);
      console.log("final showgroup", this.showGroups);
    } else {
      this.showGroups = this.filteredGroups.slice(0,this.startingSlice);
      this.currentSlice[5] = this.startingSlice;
    }
    return this.currentSlice[5];
  }

  public goBack() {
    GlobalService.SearchData = "";
    this.globalService.goBack();
  }

  public getGlobalSearch(){
    return GlobalService.SearchData;
  }

  public isDesktop(){
    return this.globalService.isDesktop();
  }
  
  isIos(){
    return this.globalService.isIos();
  }
}
