<ion-header>
  <ion-toolbar class="toolbar">
    <ion-buttons mode="md" slot="start">
      <ion-button mode="md" (click)="goBack()">
        <ion-icon class="new-style-icon" [src]="'assets/icon/chevron-left.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-avatar *ngIf="contact && (!contact.avatar || contact.avatar.includes('contacts.svg'))" 
  class="avatar no-avatar" [ngClass]="{'desktop-avatar': isDesktop()}">
    <ion-icon [src]="'assets/icon/contacts.svg'"></ion-icon>
  </ion-avatar>
  <ion-avatar *ngIf="contact && (contact.avatar && !contact.avatar.includes('contacts.svg'))" 
  class="avatar" [ngClass]="{'desktop-avatar': isDesktop()}">
    <ion-img [src]="contact.avatar"></ion-img>
  </ion-avatar>
</ion-header>

<div class="disable-background" *ngIf="isLoading || (isLoadingActions && currentTab == ContactTabs.Information)">
  <div class="pop-up">
    <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
  </div>
</div>

<div *ngIf="returnContactService().RefreshContactActions">
  {{ callRefreshFunction() }}
</div>

<div *ngIf="contact" class="edit-fab-container">
  <ion-fab mode="md" horizontal="end" fill="clear" class="fab-list" style="z-index: 11 !important;">
    <ion-fab-button mode="md" class="main-fab" fill="clear" [translucent]="true" style="z-index: 11 !important;">
      <ion-icon class="cog-menu" [src]="'assets/icon/options.svg'"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="bottom">
      <ion-fab-button (click)="editContact()">
        <ion-icon class="cog-menu-options" [src]="'assets/icon/edit.svg'"></ion-icon>
      </ion-fab-button >
      <ion-fab-button *ngIf="isCordova()" (click)="exportContact()">
        <ion-icon class="cog-menu-options" [src]="'assets/icon/share.svg'"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="switchTabTo(ContactTabs.History)">
        <i class="fas fa-clock details-icon"></i>
      </ion-fab-button>
      <!-- <ion-fab-button (click)="exportQR()">
        <i class="fas fa-qrcode details-icon"></i>
      </ion-fab-button> -->
      <!-- <ion-fab-button (click)="copyContactToClipboard()">
        <i class="fas fa-clone details-icon"></i>
      </ion-fab-button> -->
      <ion-fab-button (click)="deleteContact()">
        <ion-icon class="cog-menu-options" [src]="'assets/icon/delete.svg'"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
</div>

<ion-content *ngIf="contact">
  <div *ngIf="isIos()" class="twentypx-padding"></div>
  <div class="subheader shadow">
    <div class="text-container">
      <ion-text *ngIf="debugMode()" class="ion-no-padding ion-text-capitalize contact-title">{{idUser}}<br></ion-text>
      <ion-text class="ion-no-padding ion-text-capitalize contact-title">
        <div *ngIf="contact.first_name || contact.last_name">{{contact.first_name}} {{contact.last_name}}</div>
        <div *ngIf="!contact.first_name && !contact.last_name">
          {{contact.company}}
          <ion-text *ngIf="contact.company && contact.function"> - </ion-text>
          {{contact.function}}
        </div>
      </ion-text>
      <ion-text *ngIf="contact.first_name || contact.last_name" color="medium" class="ion-no-padding ion-text-capitalize contact-subtitle">
        <div>
          {{contact.company}}
          <ion-text *ngIf="contact.company && contact.function"> - </ion-text>
          {{contact.function}}
        </div>
      </ion-text>
      <br *ngIf="(!contact.first_name && !contact.last_name) || (!contact.company && !contact.function)">
      <ion-text *ngIf="contact.relationshipstatus && contact.relationshipstatus != 'MISCELLANEOUS'" color="medium" class="ion-no-padding ion-text-capitalize contact-subtitle">{{contact.relationshipstatus | translate}}
      </ion-text>
    </div>

    <div class="tabs-contact-details">
    <ion-item lines="none" class="detail-tabs ion-no-padding" [button]="true" [style]="getStyleForTab(ContactTabs.Information)" (click)="switchTabTo(ContactTabs.Information)">
      <div [ngClass]="{'selected-category': returnContactService().ContactDetailPageTab == ContactTabs.Information}">
        <div class="tab-element-align">
          <ion-icon class="cog-menu-options" [src]="'assets/icon/info-circle.svg'"></ion-icon>
        </div>

        <ion-label class="tab-name">{{'INFO' | translate}}</ion-label>
      </div>
    </ion-item>

    <ion-item lines="none" class="detail-tabs ion-no-padding" [button]="true" [style]="getStyleForTab(ContactTabs.Actions)" (click)="switchTabTo(ContactTabs.Actions)">
      <div [ngClass]="{'selected-category': returnContactService().ContactDetailPageTab == ContactTabs.Actions}">
        <div class="tab-element-align">
          <ion-icon class="cog-menu-options" [src]="'assets/icon/contact-actions.svg'"></ion-icon>
        </div>
        
        <ion-label class="tab-name">{{'ACTIONS' | translate}}<div *ngIf="hasNoActions()" style="display: inline-block">!</div></ion-label>
        
      </div>
    </ion-item>

    <ion-item lines="none" class="detail-tabs ion-no-padding" [button]="true" [style]="getStyleForTab(ContactTabs.Notes)" (click)="switchTabTo(ContactTabs.Notes)">
      <div [ngClass]="{'selected-category': returnContactService().ContactDetailPageTab == ContactTabs.Notes}">
        <div class="tab-element-align">
          <ion-icon class="cog-menu-options" [src]="'assets/icon/notes.svg'"></ion-icon>
        </div>

        <ion-label class="tab-name">{{'NOTES' | translate}}</ion-label>
      </div>
    </ion-item>

    <ion-item lines="none" class="detail-tabs ion-no-padding" [button]="true" [style]="getStyleForTab(ContactTabs.Touchpoints)" (click)="switchTabTo(ContactTabs.Touchpoints)">
      <div [ngClass]="{'selected-category': returnContactService().ContactDetailPageTab == ContactTabs.Touchpoints}">
        <div class="tab-element-align">
          <ion-icon class="cog-menu-options" [src]="'assets/icon/touchpoints.svg'"></ion-icon>
        </div>

        <ion-label class="tab-name">{{'TOUCHPOINTSPAGE' | translate}}</ion-label>
      </div>
    </ion-item>
    </div>
  </div>

  <div *ngIf="currentTab == ContactTabs.Information">
    <div *ngIf="!isLoadingContactDetail && 
    (!contact.telephone_list || contact.telephone_list.length == 0) && 
    (!contact.email_list || contact.email_list.length == 0) &&
    (!contact.address_list || contact.address_list.length == 0) && 
    (!contact.url_list || contact.url_list.length == 0)">
      <div class="center-text add-new" style="margin-top: 15px;">
        <ion-button color="dark" fill="clear">
          <div (click)="editContact()">
            <ion-icon class="new-style-icon text-color" [src]="'assets/icon/edit.svg'" style="margin-top: 5px;"></ion-icon>
          </div>
        </ion-button>
      </div>
    </div>

    <div class="contact-detail-info-top-padding"></div>
    <div class="corrected-card" *ngIf="!isLoadingContactDetail && contact.telephone_list && contact.telephone_list.length > 0 || 
      !isLoadingContactDetail && contact.email_list && contact.email_list.length > 0 || 
      !isLoadingContactDetail && contact.address_list && contact.address_list.length > 0 || 
      !isLoadingContactDetail && contact.url_list && contact.url_list.length > 0">

      <ion-card class="shadow" *ngIf="contact.telephone_list && contact.telephone_list.length > 0">
        <ion-card-content class="detail-card">
          <div *ngFor="let phone of contact.telephone_list; let i = index">
            <ion-item class="detail-list" lines="none" *ngIf="i == 0 || (i > 0 && phoneExtended)">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="2" class="row-icon-container" (click)="SelectPhoneNumber(phone.number, '')">
                    <ion-icon class="cog-menu-options" [src]="'assets/icon/phone.svg'"></ion-icon>
                  </ion-col>
                  <ion-col size="9" (click)="SelectPhoneNumber(phone.number, '')">
                    <ion-row>
                      <ion-text class="ion-text-capitalize">
                        {{phone.number_type | translate}}
                      </ion-text>
                    </ion-row>
                    <ion-row>
                      <ion-text class="detail-value">
                        {{phone.number}}
                      </ion-text>
                    </ion-row>
                  </ion-col>
                  <ion-col size="1" (click)="copyToClipboard(phone.number)">
                    <div class="row-icon ">
                      <i class="fas fa-clone cog-menu-options"></i>
                    </div>
                  </ion-col>

                  <!-- <ion-col size="1" *ngIf="i == 0 && contact.telephone_list.length > 1" (click)="extendPhoneNumbers()">
                    <div *ngIf="!phoneExtended" class="row-icon ">
                      <ion-icon class="cog-menu-options" [src]="'assets/icon/chevron-down.svg'"></ion-icon>
                    </div>
                    <div *ngIf="phoneExtended" class="row-icon">
                      <ion-icon class="cog-menu-options" [src]="'assets/icon/chevron-up.svg'"></ion-icon>
                    </div>
                  </ion-col> -->
                </ion-row>
              </ion-grid>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
  
      <ion-card class="shadow" *ngIf="contact.email_list && contact.email_list.length > 0">
        <ion-card-content class="detail-card">
          <div *ngFor="let email of contact.email_list; let i = index">
            <ion-item class="detail-list" lines="none" *ngIf="i == 0 || (i > 0 && emailExtended)">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="2" class="row-icon-container" (click)="mailIT(email.email)">
                    <ion-icon class="cog-menu-options" [src]="'assets/icon/email.svg'"></ion-icon>
                  </ion-col>
                  <ion-col size="9" (click)="mailIT(email.email)">
                    <ion-row>
                      <ion-text class="ion-text-capitalize">
                        {{email.email_type | translate}}
                      </ion-text>
                    </ion-row>
                    <ion-row>
                      <ion-text class="detail-value">
                        {{email.email}}
                      </ion-text>
                    </ion-row>
                  </ion-col>
                  <ion-col size="1" (click)="copyToClipboard(email.email)">
                    <div class="row-icon ">
                      <i class="fas fa-clone cog-menu-options"></i>
                    </div>
                  </ion-col>
                  <!-- <ion-col size="1" *ngIf="i == 0 && contact.email_list.length > 1" (click)="extendEmails()">
                    <div *ngIf="!emailExtended" class="row-icon ">
                      <ion-icon class="cog-menu-options" [src]="'assets/icon/chevron-down.svg'"></ion-icon>
                    </div>
                    <div *ngIf="emailExtended" class="row-icon ">
                      <ion-icon class="cog-menu-options" [src]="'assets/icon/chevron-up.svg'"></ion-icon>
                    </div>
                  </ion-col> -->
                </ion-row>
              </ion-grid>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-card class="shadow" *ngIf="contact.address_list && contact.address_list.length > 0">
        <ion-card-content class="detail-card">
          <div *ngFor="let address of contact.address_list; let i = index">
            <ion-item class="detail-list" lines="none" *ngIf="i == 0 || (i > 0 && addressesExtended)">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="2" class="row-icon-container location-icon-container" (click)="openAddress(address)">
                    <ion-icon class="cog-menu-options" [src]="'assets/icon/address.svg'"></ion-icon>
                  </ion-col>
                  <ion-col size="9" (click)="openAddress(address)">
                    <ion-row>
                      <ion-text color="primary" class="ion-text-capitalize">
                        {{address.street}} {{address.street_no}}
                      </ion-text>
                    </ion-row>
                    <ion-row>
                      <ion-text class="detail-value">
                        {{address.zipcode}}, {{address.place}}, {{address.country}}
                      </ion-text>
                    </ion-row>
                  </ion-col>
                  <ion-col size="1" (click)="copyToClipboard(address.street + ' ' + address.street_no + ', ' + address.zipcode + ' ' + address.place + ' ' + address.country)">
                    <div class="row-icon ">
                      <i class="fas fa-clone cog-menu-options"></i>
                    </div>
                  </ion-col>
                  <!-- <ion-col size="1" *ngIf="i == 0 && contact.address_list.length > 1" (click)="extendAddresses()">
                    <div *ngIf="!addressesExtended" class="row-icon">
                      <ion-icon class="cog-menu-options" [src]="'assets/icon/chevron-down.svg'"></ion-icon>
                    </div>
                    <div *ngIf="addressesExtended" class="row-icon">
                      <ion-icon class="cog-menu-options" [src]="'assets/icon/chevron-up.svg'"></ion-icon>
                    </div>
                  </ion-col> -->
                </ion-row>
              </ion-grid>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-card class="shadow" *ngIf="websiteList && websiteList.length > 0">
        <ion-card-content class="detail-card">
          <div *ngFor="let website of websiteList; let i = index">
            <ion-item class="detail-list" lines="none" *ngIf="i == 0 || (i > 0 && websitesExtended)">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="2" class="row-icon-container" (click)="openURL(website.url)">
                    <ion-icon class="cog-menu-options" [src]="getImageForURL(website)"></ion-icon>
                  </ion-col>
                  <ion-col size="9" (click)="openURL(website.url)">
                    <ion-row>
                      <ion-text color="primary">
                        {{website.url}}
                      </ion-text>
                    </ion-row>
                    <ion-row>
                      <ion-text class="detail-value">
                        {{ "WEBSITE" | translate}}
                      </ion-text>
                    </ion-row>
                  </ion-col>
                  <ion-col size="1" (click)="copyToClipboard(website.url)">
                    <div class="row-icon ">
                      <i class="fas fa-clone cog-menu-options"></i>
                    </div>
                  </ion-col>
                  <!-- <ion-col size="1" *ngIf="i == 0 && websiteList.length > 1" (click)="extendWebsites()">
                    <div *ngIf="!websitesExtended" class="row-icon ">
                      <ion-icon class="cog-menu-options" [src]="'assets/icon/chevron-down.svg'"></ion-icon>
                    </div>
                    <div *ngIf="websitesExtended" class="row-icon ">
                      <ion-icon class="cog-menu-options" [src]="'assets/icon/chevron-up.svg'"></ion-icon>
                    </div>
                  </ion-col> -->
                </ion-row>
              </ion-grid>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-card class="shadow" *ngIf="urlList && urlList.length > 0">
        <ion-card-content>
          <ion-grid class="ion-no-padding">
            <ion-row class="center-text">
              <ion-col class="ion-justify-content-center" *ngFor="let link of urlList; let i = index">
                <ion-icon class="social-icon text-color-dark" [src]="getImageForURL(link)" (click)="openURL(link.url)"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </div>
  </div>

  <div *ngIf="currentTab == ContactTabs.Actions">
    <div class="center-text add-new">
      <ion-button color="dark" fill="clear">
        <div (click)="addaction()">
          <ion-icon class="add-item text-color-dark" [src]="'assets/icon/plus-circle.svg'"></ion-icon>
        </div>
      </ion-button>
    </div>

    <div *ngIf="isLoadingActions || !actions">
      <div class="corrected-card"><app-action-item [lines]="false" [clickable]="false"></app-action-item></div>
      <div class="corrected-card"><app-action-item [lines]="false" [clickable]="false"></app-action-item></div>
      <div class="corrected-card"><app-action-item [lines]="false" [clickable]="false"></app-action-item></div>
      <div class="corrected-card"><app-action-item [lines]="false" [clickable]="false"></app-action-item></div>
    </div>

    <div *ngIf="!isLoadingActions && actions">
      <div *ngFor="let action of actions">
        <div class="corrected-card" *ngIf="action && (!action.status || action.status == 0)">
          <app-action-item [cDetail]="true" (onFinish)="switchTabTo(ContactTabs.Touchpoints)" (onDelete)="deleteThisAction(action)" [lines]="false" [action]="action" [displayAvatar]="false"></app-action-item>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentTab == ContactTabs.Notes">
    <div class="center-text add-new">
      <ion-button color="dark" fill="clear">
        <div (click)="addnote()">
          <ion-icon class="add-item text-color-dark" [src]="'assets/icon/plus-circle.svg'"></ion-icon>
        </div>
      </ion-button>
    </div>
    <div class="corrected-card" *ngIf="isLoadingNotes || !notes">
      <ion-card *ngFor="let i of [1,2,3,4]">
        <app-note-item [lines]="false" [clickable]="false" [displayAvatar]="false"></app-note-item>
      </ion-card>
    </div>

    <div class="corrected-card" *ngIf="!isLoadingNotes && notes">
      <ion-card *ngFor="let note of notes">
        <app-note-item [lines]="false" [note]="note" [displayAvatar]="false"></app-note-item>
      </ion-card>
    </div>
  </div>

  <div *ngIf="currentTab == ContactTabs.Touchpoints">
    <div class="center-text add-new">
      <ion-button color="dark" fill="clear">
        <div (click)="addtouchpoint()">
          <ion-icon class="add-item text-color-dark" [src]="'assets/icon/plus-circle.svg'"></ion-icon>
        </div>
      </ion-button>
    </div>
    <div *ngIf="isLoadingActions || !actions">
      <div class="corrected-card" *ngFor="let i of [1,2,3,4]">
        <app-action-item [cDetail]="true" [lines]="false" [clickable]="false"></app-action-item>
      </div>
    </div>

    <div *ngIf="!isLoadingActions && actions">
      <ion-item class="gray-border page-element-center" *ngIf="touchpoints.length > tpSliceLimit">
        <div (click)="increaseTPLimit()" class="left-large-button">
          <div><div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div></div>
        </div>
        <div (click)="resetTPScrollDown()" class="right-small-button">
          <div class="center-text full-width show-more-less">&darr;</div>
        </div>
      </ion-item>
      <ion-item class="gray-border page-element-center" *ngIf="touchpoints.length <= tpSliceLimit" (click)="resetTPScrollDown()">
        <div class="center-text full-width show-more-less">&darr;</div>
      </ion-item>
      <div *ngFor="let action of touchpointSlice">
        <div class="corrected-card" *ngIf="action.status && action.status != 0">
          <app-action-item [cDetail]="true" (onFinish)="refreshActions()" (onDelete)="refreshActions()" [lines]="false" [action]="action" [displayAvatar]="false"></app-action-item>
        </div>
      </div>
      <div *ngIf="!this.firstTPScroll" #snaptobottom id="snaptobottom">
        {{scrollDown()}}
      </div>
      <div class="bottom-button" (click)="this.addaction()" *ngIf="openActions == 0 && this.actions && this.actions.length > 0">
        {{ "NEWACTION" | translate }}
      </div>
    </div>
  </div>
  
  <div *ngIf="currentTab == ContactTabs.Actionpath">
    <div class="center-text add-new">
      <ion-button color="dark" fill="clear">
        <div (click)="addactionpath()">
          <ion-icon class="add-item text-color-dark" [src]="'assets/icon/plus-circle.svg'"></ion-icon>
        </div>
      </ion-button>
    </div>

    <div *ngIf="isLoadingActionpaths && !actionpaths">
      <ion-card *ngFor="let i of [1,2,3,4]">
        <ion-card-content>
          <app-actionpath-item [lines]="false" [clickable]="false"></app-actionpath-item>
        </ion-card-content>
      </ion-card>
    </div>

    <div *ngIf="!isLoadingActionpaths && actionpaths">
      <ion-card *ngFor="let actionpath of actionpaths">
        <ion-card-content>
          <app-actionpath-item (onDelete)="refreshActionPaths()" [lines]="false" [actionpath]="actionpath"></app-actionpath-item>
        </ion-card-content>
      </ion-card>
    </div>
  </div>

  <div *ngIf="currentTab == ContactTabs.History">
    <div class="loading-history" *ngIf="isLoadingHistory">
      {{ "LOADINGHISTORY" | translate }}<ion-text *ngFor="let item of [].constructor(historyLoader); let i = index">.</ion-text>
    </div>

    <div class="history-holder">
      <div *ngFor="let historyItem of history">
        <!-- {{ historyItem["historyDate"] }} -->
        <ion-card *ngIf="historyItem['historyType'] == 'note'" class="note-card">
          <app-note-item [lines]="false" [note]="historyItem" [displayAvatar]="false"></app-note-item>
        </ion-card>
        <app-action-item [cDetail]="true" *ngIf="historyItem['historyType'] == 'action'" (onFinish)="deleteThisAction(history)" (onDelete)="deleteThisAction(history)" [lines]="false" [action]="historyItem" [displayAvatar]="false"></app-action-item>
        <app-actionpath-item *ngIf="historyItem['historyType'] == 'actionpath'" (onDelete)="refreshActionPaths()" [lines]="false" [actionpath]="historyItem" [elementCenter]="false"></app-actionpath-item>
      </div>
    </div>
  </div>
</ion-content>

<ion-content *ngIf="!contact">
  <div *ngIf="isIos()" class="twentypx-padding"></div>
  <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
</ion-content>