import { Component, OnInit, ViewChild } from "@angular/core";
import actionpath from "src/app/models/actionpath";
import { NavController, ModalController, AlertController, Platform } from "@ionic/angular";
import { Router, ActivatedRoute } from "@angular/router";
import { ActionService } from "src/app/services/action.service";
import { ActionpathService } from "src/app/services/actionpath.service";
import { ContactService } from "src/app/services/contact.service";
//import { TouchpointService } from "src/app/services/touchpoint.service";
import { TranslateService } from '@ngx-translate/core';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import Action from "src/app/models/action";
import Contact from "src/app/models/contact";
import Touchpoint from "src/app/models/touchpoint";
import { SelectContactsPage } from "../select-contacts/select-contacts.page";
import { SelectActionsPage } from "../select-actions/select-actions.page";
import { SelectTouchpointsPage } from "../select-touchpoints/select-touchpoints.page";
import { GlobalService } from 'src/app/services/global.service'

@Component({
  selector: 'app-add-actionpath',
  templateUrl: './add-actionpath.page.html',
  styleUrls: ['./add-actionpath.page.scss'],
})
export class AddactionpathPage implements OnInit {
  @ViewChild('titleId', {static: false}) titleInput;
  
  public isEditingExisting: boolean = false;
  public original: actionpath;
  public ionicForm;
  public isSubmitted: boolean = false;
  public showContactSelect: boolean = false;
  public id_action_path: number;
  public title: string = "";
  public creation: string;
  public status: string = "OPEN";
  public subgoalCountType: string = "TOUCHPOINTS";
  public subgoalName: string;
  public subgoalCount: number = 0;
  public subgoalMax: number = 10;
  public priority = false;
  public startingStatus: string = "";
  public result: string;
  public description: string;
  public selectedContacts: Contact[];
  public selectedActions: Action[];
  public selectedTouchpoints: Action[];
  public selectedActionSequences: Action[];
  public currentContact;
  public date: string = new Date().toISOString();
  public time: string = "08:00";
  public showTimeSelection: boolean = false;
  public useFinalDate: boolean = true;
  public finalGoal: actionpath;

  get controls() {
    return this.ionicForm.controls;
  }

  constructor(
    private navController: NavController,
    private modalController: ModalController,
    private actionService: ActionService,
    private router: Router,
    private route: ActivatedRoute,
    private contactService: ContactService,
    //private touchpointService: TouchpointService,
    private actionpathService: ActionpathService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private platform: Platform,
    private globalService: GlobalService
  ) {
  }

  async ngOnInit() {
    this.isSubmitted = false;
    this.selectedContacts = [];
    this.selectedActions = [];
    this.selectedTouchpoints = [];
    this.selectedActionSequences = [];
    this.creation = new Date(Date.now() + 60 * 60 * 1000).toISOString();

    await this.ionViewDidEnterFromInit();
  }

  ionViewWillLeaveManual () {
    ActionpathService.SetIsEditing(false);
    ActionpathService.IsFollowUp = false;
  }

  async ionViewDidEnterFromInit() {
    this.creation = new Date(Date.now() + 60 * 60 * 1000).toISOString();
    let baseDate = new Date(Date.now());
    //Grab the current date and add 7 days.
    baseDate.setDate(baseDate.getDate() + 7);
    baseDate.setDate(this.setToDay(baseDate, 5).getDate());
    let baseMonth = baseDate.getMonth() + 1;
    this.date = baseDate.getFullYear() + "-" + ("0" + baseMonth).slice(-2) + "-" + ("0" + baseDate.getDate()).slice(-2);
    this.time = "20:00";

    if(!ActionpathService.PriorityExists) this.priority = true;

    this.isEditingExisting = ActionpathService.IsEditing;
    this.original = ActionpathService.Actionpath;
    this.selectedContacts = [];
    GlobalService.log("Current actionpath");
    GlobalService.log(this.original);
    
    if (this.isEditingExisting || ActionpathService.IsFollowUp) {
      if(this.original.title) this.title = this.original.title;
      if(this.original.status) this.status = this.original.status;
      if(this.original.status) this.startingStatus = this.original.status;
      if(this.original.creation) this.creation = this.original.creation;
      if(this.original.description) this.description = this.original.description;
      if(this.original.result) this.result = this.original.result;
      if(this.original.description) this.description = this.original.description;
      if(this.original.id_action_path) this.id_action_path = this.original.id_action_path;
      if(this.original.subgoal_count_type) this.subgoalCountType = this.original.subgoal_count_type;
      if(this.original.subgoal_name) this.subgoalName = this.original.subgoal_name;
      if(this.original.subgoal_current_amount) this.subgoalCount = this.original.subgoal_current_amount;
      if(this.original.subgoal_final_amount) this.subgoalMax = this.original.subgoal_final_amount;
      this.priority = this.original.priority;

      if(this.original.finaldate){
        this.useFinalDate = true;
        GlobalService.log("original final date", this.original.finaldate);
        baseDate = new Date(this.original.finaldate);
        baseMonth = baseDate.getMonth() + 1;
        this.date = baseDate.getFullYear() + "-" + ("0" + baseMonth).slice(-2) + "-" + ("0" + baseDate.getDate()).slice(-2);
  
        this.time = GlobalService.sliceToTwo(new Date(this.original.finaldate).getHours()) + ":" 
        + GlobalService.sliceToTwo(new Date(this.original.finaldate).getMinutes());
      } else this.useFinalDate = false;

      if (this.original.contacts && this.original.contacts.length > 0) {
        for (let i = 0; i < this.original.contacts.length; i++) {
          this.contactService
            .getMiniContact(this.original.contacts[i].id_contact)
            .then((contact) => {
              this.selectedContacts.push(contact);
            });
        }
      }

      if (this.original.actions && this.original.actions.length > 0) {
        for (let i = 0; i < this.original.actions.length; i++) {
          this.actionService
          .getAction(this.original.actions[i].id_action)
            .then((action) => {
              if(this.subgoalCountType == "ACTIONSEQUENCES"){
                this.selectedActionSequences.push(action);
              } else {
                if(!action.status || action.status == 0 || action.status == 1){
                  this.selectedActions.push(action);
                } else {
                  this.selectedTouchpoints.push(action);
                }
              }
            });
        }
      }
    }

    if (!this.isEditingExisting) {
      this.loadContact();
    }

    await this.actionpathService.checkMaximum();

    // if(ActionpathService.reachedMaximum && this.isEditingExisting == false){
    //   this.status = "INACTIVE";
    // } else if(!ActionpathService.reachedMaximum && this.isEditingExisting == false) {
    //   this.status = "OPEN";
    // }
    
    if (!this.isEditingExisting) {
      while(!document.getElementById("titleId")) await GlobalService.sleep(100);
      document.getElementById("titleId").getElementsByTagName("input")[0].focus();
    }
  }
  

  public removeContact(contact: Contact) {
    this.selectedContacts.splice(this.selectedContacts.indexOf(contact), 1);
  }

  public goBack(dismissed: boolean = false) {
    if(ActionpathService.FromAction){
      ActionpathService.FromAction = false;
      this.ionViewWillLeaveManual();
      this.modalController.dismiss({
        dismissed: dismissed,
        goal: this.finalGoal
      });
    } else {
      this.ionViewWillLeaveManual();
      this.navController.back();
    }
  }

  public createactionpathObject(): actionpath {
    let actionpath: any = {};
    actionpath.title = GlobalService.doNotAllowSpecial(this.title);
    actionpath.creation = new Date(this.creation).getTime();

    actionpath.status = this.status;
    actionpath.result = this.result;
    actionpath.description = GlobalService.doNotAllowSpecial(this.description);
    actionpath.contacts = [];
    if(this.status == "OPEN") actionpath.priority = this.priority;
    else actionpath.priority = false;

    actionpath.subgoal_count_type = this.subgoalCountType;
    actionpath.subgoal_name = this.subgoalName;
    actionpath.subgoal_current_amount = this.subgoalCount;
    actionpath.subgoal_final_amount = this.subgoalMax;

    if(this.useFinalDate){
      let newdate = new Date(this.date);
      newdate.setHours(parseInt(this.time.split(":")[0]));
      newdate.setMinutes(parseInt(this.time.split(":")[1]));
      actionpath.final_date = newdate.getTime();
    }

    if(this.isEditingExisting == false){
      actionpath.creation = new Date().getTime();
    } else {
      actionpath.id_action_path = this.id_action_path;
      actionpath.creation = this.original.creation;
    }

    if (this.selectedContacts.length) {
      for (let i = 0; i < this.selectedContacts.length; i++) {
        actionpath.contacts.push({
          id_contact: this.selectedContacts[i].id_contact,
        });
      }
    }

    actionpath.actions = [];
    actionpath.touchpoints = [];
    
    if(this.subgoalCountType == 'ACTIONSEQUENCES'){
      if (this.selectedActionSequences.length) {
        for (let i = 0; i < this.selectedActionSequences.length; i++) {
          actionpath.actions.push({
            id_action: this.selectedActionSequences[i].id_action,
          });
        }
      }
    } else {
      if (this.selectedActions.length) {
        for (let i = 0; i < this.selectedActions.length; i++) {
          actionpath.actions.push({
            id_action: this.selectedActions[i].id_action,
          });
        }
      }
      
      GlobalService.log(this.selectedTouchpoints);
      if (this.selectedTouchpoints.length) {
        for (let i = 0; i < this.selectedTouchpoints.length; i++) {
          actionpath.touchpoints.push({
            id_action: this.selectedTouchpoints[i].id_action,
          });
        }
      }
    }

    if(actionpath.priority) ActionpathService.PriorityExists = false;
    
    return actionpath;
  }

  public validateactionpath(): boolean {
    if (this.isStringEmpty(this.title)){
      this.globalService.genericError(this.translateService.instant("FILLINTITLE"));
      return false;
    } 
    if(this.subgoalCountType == "MANUAL") 
    {
      if (this.isStringEmpty(this.subgoalName)) {
        this.globalService.genericError(this.translateService.instant("FILLINSUBGOALNAME"));
        return false;
      }
    }
    return true;
  }

  public isStringEmpty(str) {
    return !str || str.length === 0 || !str.trim();
  }

  public submit() {
    if (!this.validateactionpath()) return;
    let actionpath = this.createactionpathObject();
    if(this.isSubmitted == false ){ 
      this.isSubmitted = true;
    
      GlobalService.log(actionpath);

      if (this.isEditingExisting) {
        this.updateactionpath(actionpath);
      } else {
        this.createactionpath(actionpath);
      }
    }
  }

  public async updateactionpath(actionpath: actionpath) {
    actionpath.touchpoints.forEach(touchpoint => {
      actionpath.actions.push(touchpoint);
    });
    actionpath.touchpoints = [];

    let res = await this.actionpathService.updateactionpath(actionpath)
    GlobalService.log(res);
    this.finalGoal = res;
    ActionpathService.SetCurrentactionpath(this.finalGoal);
    this.goBack(true)
  }

  async createactionpath(actionpath: actionpath) {
    actionpath.touchpoints.forEach(touchpoint => {
      actionpath.actions.push(touchpoint);
    });
    actionpath.touchpoints = [];     
    let res = await this.actionpathService.createactionpath(actionpath)    
    this.globalService.returnToast("ADDACTIONPATHSUCCESS");

    this.finalGoal = res;
    if(ActionpathService.IsFollowUp) ActionpathService.SetCurrentactionpath(this.finalGoal);
    
    this.goBack(true);
  }
  
  public loadContact(){
    this.selectedContacts = [];
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.currentContact = params.id_contact;
        if(this.currentContact != undefined){
          GlobalService.log(this.currentContact);
          this.contactService.getContact(this.currentContact).then((res) => {
            this.selectedContacts.push(res);
          });
          GlobalService.log(this.selectedContacts);
        }
      }
    });
  }

  public async selectContacts() {
    let safelist = [];

    this.selectedContacts.forEach(element => {
      safelist.push(element);
    });
    
    const modal = await this.modalController.create({
      component: SelectContactsPage,
      componentProps: {
        selectedContacts: this.selectedContacts,
        addToEntity: true
      },
    });

    modal.onDidDismiss().then((data) => {
      GlobalService.log(safelist);
      GlobalService.log(this.selectedContacts);
      GlobalService.log(this.selectedContacts);
      GlobalService.log(data.data);
      if(data.data.dismissed == true){
        this.selectedContacts = this.selectedContacts;
      } else {
        this.selectedContacts = safelist;
        this.selectedContacts = safelist;
      }
    });

    return await modal.present();
  }

  public async addAction(){
    GlobalService.log("attempted to add actions");
    GlobalService.log(this.selectedActions);
    
    const modal = await this.modalController.create({
      component: SelectActionsPage,
      componentProps: {
        selectedActions: this.selectedActions,
      },
    });

    modal.onDidDismiss().then((data) => {
      this.selectedActions = data.data.actions;
    });

    return await modal.present();
  }

  public async addTouchpoint(){
    GlobalService.log("attempted to add touchpoints");
    const modal = await this.modalController.create({
      component: SelectTouchpointsPage,
      componentProps: {
        selectedActions: this.selectedTouchpoints,
      },
    });

    modal.onDidDismiss().then((data) => {
      this.selectedTouchpoints = data.data.touchpoints;
    });

    return await modal.present();
  }

  public async addActionSequences(){
    GlobalService.log("attempted to add action sequences");
    GlobalService.log(this.selectedActionSequences);
    
    const modal = await this.modalController.create({
      component: SelectActionsPage,
      componentProps: {
        selectedActions: this.selectedActionSequences,
        sequencesOnly: true
      },
    });

    modal.onDidDismiss().then((data) => {
      this.selectedActionSequences = data.data.actions;
    });

    return await modal.present();
  }

  public setToDay(usedDate: Date, day: number){
    let currentDay = usedDate.getDay();
    let distance = day - currentDay;
    usedDate.setDate(usedDate.getDate() + distance);
    return usedDate;
  }

  returnMaximum(){
    return ActionpathService.reachedMaximum;
  }

  onEnter(event) {
    this.globalService.onEnter(event)
  }

  isDesktop(){
    return this.globalService.isDesktop();
  }
  
  isIos(){
    return this.globalService.isIos();
  }

  public toggleTime(){
    if(!this.showTimeSelection) this.showTimeSelection = true;
    else this.showTimeSelection = false;
  }

  public async unsavedData(){
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("YES"),
      handler: async () => { 
        this.goBack();
      },
    });
    buttons.push({
      text: this.translateService.instant("NO"),
    });
    
    this.globalService.ionicAlert(
      buttons,
      "UNSAVEDDATA",
      "DOYOUWANTTOLEAVEWITHOUTSAVING",
      "yes-no-alert"
    );
  }
}
