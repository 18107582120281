<ion-header>
  <ion-toolbar>
    <ion-buttons mode="md" slot="start">
      <ion-button mode="md" (click)="goBack()">
        <ion-icon class="new-style-icon" [src]="'assets/icon/chevron-left.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="finish-select-button" (click)="submit()" *ngIf="selectedContacts && selectedContacts.length > 0">
        <ion-icon class="new-style-icon v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text *ngIf="!addToEntity" class="v-done">{{"DONE" | translate}}</ion-text>
        <ion-text *ngIf="addToEntity" class="v-done faded">{{"ADD" | translate}}</ion-text>
      </ion-button>
      <ion-button class="finish-select-button" *ngIf="!selectedContacts || selectedContacts.length == 0">
        <ion-icon class="new-style-icon faded v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text *ngIf="!addToEntity" class="v-done faded">{{"DONE" | translate}}</ion-text>
        <ion-text *ngIf="addToEntity" class="v-done faded">{{"ADD" | translate}}</ion-text>
      </ion-button>
    </ion-buttons>

    <app-header [title]="'SELECTCONTACTS' | translate" ></app-header>
  </ion-toolbar>

  <ion-searchbar search-icon="search-outline" mode="md" clear-icon="undefined" [placeholder]="'SEARCH' | translate" 
  (ionChange)="searchContacts($event)"></ion-searchbar>
</ion-header>
<ion-content *ngIf="beginLoading">
  <div [ngClass]="{'ios-search-margin': isIos()}"  class="search-margin"></div>

  <div>
    <ion-list *ngIf="this.isLoadingContacts && (!contacts || contacts.length == 0) || importing == true && isLoadingImport()">
      <ion-item><ion-label class="separator"><h1>&#8205;</h1></ion-label> </ion-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <ion-item><ion-label class="separator"><h1>&#8205;</h1></ion-label></ion-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <div class="loading-spinner">
        <ion-spinner name="circles" class="spinner-non-import" *ngIf="!importing"></ion-spinner>
        <ion-text class="waiting-text" *ngIf="importing">
          <ion-spinner name="circles" class="waiting-spinner"></ion-spinner>
          <div class="may-take-minutes">
            {{ "THISMAYTAKEAFEWMINUTES" | translate }}
          </div>
        </ion-text>
      </div>
    </ion-list>
  </div>

  <div *ngIf="!this.isLoadingContacts && contacts || importing == true && !isLoadingImport()">
    <div class="center-text select-page-buttonholder">
      <ion-button *ngIf="pageNumber() != 0" color="medium" (click)="previous()">
        {{ "PREVIOUS" | translate }} 10
      </ion-button>
      <ion-button color="medium" (click)="selectAll()" *ngIf="!maximum && selectAllAllowed">
        {{ "SELECTALL" | translate }}
      </ion-button>
      <ion-button *ngIf="contacts.length > this.pageSize + pageNumber()" color="medium" (click)="next()">
        {{ "NEXT" | translate }} 10
      </ion-button>
    </div>

    <ion-list>
      <div *ngFor="let contact of contacts; let i=index">
      <ion-item [ngClass]="{'selected-item': contactIsSelected(contact)}" ion-button (click)="selectContact(contact)" *ngIf="i >= pageNumber() && i < pageNumber()+this.pageSize" [button]="true">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col>
              <app-contact-item class="select-contact-holder" [importing]="importing" [contact]="contact" [clickable]="false" [lines]="false"></app-contact-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </div>
    </ion-list>
  </div>
</ion-content>
<ion-content *ngIf="!beginLoading">
  <div>
    <ion-list>
      <ion-item><ion-label class="separator"><h1>&#8205;</h1></ion-label> </ion-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <ion-item><ion-label class="separator"><h1>&#8205;</h1></ion-label></ion-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <div class="loading-spinner">
        <ion-spinner name="circles" class="spinner-non-import" *ngIf="!importing"></ion-spinner>
        <ion-text class="waiting-text" *ngIf="importing">
          <ion-spinner name="circles" class="waiting-spinner"></ion-spinner>
          <div class="may-take-minutes">
            {{ "THISMAYTAKEAFEWMINUTES" | translate }}
          </div>
        </ion-text>
      </div>
    </ion-list>
  </div>
</ion-content>