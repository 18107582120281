import { Injectable } from "@angular/core";
import { Contacts, PhoneType, ContactInput, EmailType, PostalAddressType } from '@capacitor-community/contacts';
import { Platform, ModalController, AlertController, ToastController } from "@ionic/angular";
import { HunterContact } from "src/app/models/contact";
import { SelectContactsPage } from "../pages/select-contacts/select-contacts.page";
import { ContactService } from "./contact.service";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from "@ionic/storage-angular";
import { AuthService } from "./auth.service";
import { NavController } from '@ionic/angular';
import { GlobalService } from "./global.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ContactTransferService {
  private importQueue: HunterContact[] = [];
  private static isImporting = {"boolean": false, "contacts" : 0, "importProgress": 0};
  public selectedContact = undefined;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private modalController: ModalController,
    private contactService: ContactService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private toastController: ToastController,
    private navController: NavController,
    public storageService: StorageService,
    private globalService: GlobalService
  ) {}

  public get ImportQueue(){
    return this.importQueue;
  }

  public static get IsImporting(){
    return this.isImporting;
  }
  
  public static set IsImporting(importAnswer){
    this.isImporting = importAnswer;
  }

  public stopQueue () {
    this.importQueue = [];
  }

  public async startQueue() {
    let queue = await this.storageService.getStorage(
      `importQueue_${(await this.authService.getUserObj()).id_user}`
    );


    if (queue && queue.length > 0) {
      GlobalService.log(`Starting import queue with ${queue.length} contacts...`);

      this.importQueue = (queue as HunterContact[]) ?? [];

      this.importInChunks(queue);
    }
  }

  public async importContacts(): Promise<void> {
    let selected = await this.openSelectContactsPage();

    if(selected.dismissed == true){
      GlobalService.log(`Select contacts page dismissed`);
      if(selected.contacts.length == 1) this.ImportSingleContactFunctionality(selected.contacts);
      else await this.importInChunks(selected.contacts as HunterContact[]);
      return;
    }
  }

  public async importInChunks(contacts: HunterContact[]) {
    GlobalService.log(`Import begins, importing ${contacts.length} contacts...`);
    ContactTransferService.IsImporting.boolean = true;
    ContactTransferService.IsImporting.contacts = contacts.length;
    let isSingleContact = false;

    const toast = await this.toastController.create({
      message: this.translateService.instant("IMPORTINGCONTACTS"),
      duration: 2000,
    });
    toast.present();

    for (let contact of contacts) {
      if (this.importQueue && this.importQueue.findIndex((queue_contact) => queue_contact.id_raw == contact.id_raw) < 0) {
        this.importQueue.push(contact);
      }
    }

    this.storageService.setStorage(`importQueue_${(await this.authService.getUserObj()).id_user}`, this.importQueue);

    for (var i = 0; i < contacts.length; i += 10) {
      let chunk = contacts.slice(i, Math.min(i + 10, contacts.length));

      chunk.forEach((c) => (c.id_contact = undefined, c.id_raw = undefined));

      let res = await this.contactService.importContacts(chunk);
      if(res.length == 1) this.selectedContact = res[0];
      this.contactService.PushContacts(res);
      for(let contact of res) ContactService.ImportList.push(contact);
      GlobalService.log(`Successfully imported ${chunk.length} contacts`);
      ContactTransferService.IsImporting.importProgress = ContactTransferService.IsImporting.importProgress + chunk.length;

      // Remove contacts from queue
      if (this.importQueue && this.importQueue.length > 0) {
        this.importQueue = this.importQueue.filter((queue_contact) => {
          return (chunk.findIndex((res_contact) => res_contact.id_raw == queue_contact.id_raw) < 0);
        });

        await this.storageService.setStorage(`importQueue_${(await this.authService.getUserObj()).id_user}`, this.importQueue);
      }
    }
    this.globalService.returnToast("CONTACTSADDEDSUCCESSFULY");
    ContactTransferService.IsImporting.boolean = false;
    ContactTransferService.IsImporting.contacts = 0;
    ContactTransferService.IsImporting.importProgress = 0;
    GlobalService.IsLoading = false;
  }

  public async selectLocalContacts() {
    const modal = await this.modalController.create({
      component: SelectContactsPage,
      componentProps: {
        selectedContacts: [],
        fetchNewContacts: true,
        getFullContacts: true,
      },
    });

    await modal.present();

    let selectedContacts = await modal
      .onDidDismiss()
      .then((data) => data.data);

    return selectedContacts;
  }

  public async exportContacts() {
    let selectedContacts = await this.selectLocalContacts();

    if(selectedContacts.dismissed == true){
      selectedContacts = selectedContacts.contacts;
    
      GlobalService.log(`Selected contacts: ${JSON.stringify(selectedContacts)}`);

      let nativeContacts: ContactInput[] = [];
  
      for (let contact of selectedContacts) {
        GlobalService.log(JSON.stringify(contact));
  
        let nativeContact = await this.createNativeContact(contact);
  
        nativeContacts.push(nativeContact);
      }
  
      for (let nativeContact of nativeContacts) {
        GlobalService.log(`Native contact: ${nativeContact}`);
  
        GlobalService.log("Attempt save");
        let x = await Contacts.createContact({"contact": nativeContact});
        GlobalService.log("Save finished");
      }
  
      if(nativeContacts && nativeContacts.length > 0){
        let buttons = [];
        buttons.push({
          text: this.translateService.instant("DISMISS")
        });
        this.globalService.ionicAlert(
          buttons,
          this.translateService.instant("SUCCESS"),
          this.translateService.instant("EXPORTCONTACTSSUCCESS")
        );
      }
    }
  }

  private capitalizeFirstLetter(str: string) {
    if(str){
      let txt = str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      return txt;
    } else return null;
  }

  private async createNativeContact(contact: HunterContact): Promise<ContactInput> {
    let finalName;
    let finalNote;
    let finalOrganization;
    let finalPhones;
    let finalEmails;
    let finalpostalAddresses;
    let finalurls;

    finalName = {
      "given": this.capitalizeFirstLetter(contact.first_name), 
      "middle": "",
      "family": this.capitalizeFirstLetter(contact.last_name),
      "prefix": "",
      "suffix": ""
    }

    finalNote = "Imported through Hunter-CRM";

    finalOrganization = {
      "company": this.capitalizeFirstLetter(contact.company),
      "jobTitle": this.capitalizeFirstLetter(contact.function),
      "department": ""
    };

    finalPhones = [];

    if(contact["telephone_list"]) {
      contact["telephone_list"].forEach(phone => {
        let phonetype: PhoneType;
        if(phone.number_type == "WORK") phonetype = PhoneType.Work;
        else if(phone.number_type == "PERSONAL") phonetype = PhoneType.Home;
        else if(phone.number_type == "MOBILE") phonetype = PhoneType.Mobile;
        else phonetype = PhoneType.Other;

        finalPhones.push({
          "type": phonetype,
          "number": phone.number
        });
      });
    }

    finalEmails = [];

    if(contact["email_list"]){ 
      contact["email_list"].forEach(email => {
        let emailtype: EmailType;
        if(email.email_type == "WORK") emailtype = EmailType.Work;
        else if(email.email_type == "PERSONAL") emailtype = EmailType.Home;
        else emailtype = EmailType.Other;

        finalEmails.push({
          type: emailtype,
          address: email.email
        });
      });
    }

    finalpostalAddresses = [];

    if(contact["address_list"]){
      contact["address_list"].forEach(address => {
        finalpostalAddresses.push({
          type: PostalAddressType.Home,
          street: address.street + " " + address.street_no,
          city: address.place,
          postcode: address.zipcode,
          country: address.country,
        });
      });
    }

    finalurls = [];

    if(contact["url_list"]){
      contact["url_list"].forEach(url => {
        finalurls.push(url.url);
      });
    }
    
    let nativeContact: ContactInput = {
      name: finalName,
      organization: finalOrganization,
      phones: finalPhones,
      emails: finalEmails,
      postalAddresses: finalpostalAddresses,
      urls: finalurls
    };

    // if (contact.avatar != undefined && !contact.avatar.includes('contacts.svg')) {
    //   nativeContact.image = { "base64String": contact.avatar };
    // }

    return nativeContact;
  }

  private async openSelectContactsPage(): Promise<any> {
    const modal = await this.modalController.create({
      component: SelectContactsPage,
      componentProps: {
        selectedContacts: [],
        getNativeContacts: true,
      },
    });

    await modal.present();

    return modal.onDidDismiss().then((data) => data.data);
  }

  public async exportSingleContact(contact: HunterContact) {
    let nativeContact = await this.createNativeContact(contact);

    GlobalService.log("Attempt save");
    let x = await Contacts.createContact({"contact": nativeContact});
    GlobalService.log("Save finished");
      let buttons = [];
      buttons.push({
        text: this.translateService.instant("DISMISS")
      });
      this.globalService.ionicAlert(
        buttons,
        this.translateService.instant("SUCCESS"),
        this.translateService.instant("EXPORTCONTACTSSUCCESS")
      );
  }

  checkLinkType(url: string) {
    if (url.toLowerCase().includes("facebook")) {
      return "Facebook";
    }

    if (url.toLowerCase().includes("instagram")) {
      return "Instagram";
    }

    if (url.toLowerCase().includes("linkedin")) {
      return "LinkedIn";
    }

    if (url.toLowerCase().includes("twitter")) {
      return "Twitter";
    }

    return url.toLowerCase();
  }

  fixURL(url: string) {
    const win: any = window;
    const fixedURL = win.Ionic.WebView.convertFileSrc(url);
    var returnedURL = this.sanitizer.bypassSecurityTrustResourceUrl(fixedURL);
    return returnedURL;
  }

  public arraySplitter(curArray, number) {
    let initialArray = curArray;
    GlobalService.log(initialArray);
    GlobalService.log(number);
    let secondaryArray = [];
    for (var i = 0; i < number; i++) {
      secondaryArray.push(initialArray[i]);
    }

    while (number > 0) {
      initialArray.pop(number);
      number = number - 1;
    }
    return { remainingData: initialArray, splitData: secondaryArray };
  }

  public convertCSVData(incomingData){
    let CSVData = [];
    incomingData.forEach(contact => {
      if(contact["PHONE"]){
        contact.telephone_list = [];
        contact.telephone_list.push({"number": contact["PHONE"], "number_type": "MOBILE"});
      }
      
      if(contact["EMAIL"]){
        contact.email_list = [];
        contact.email_list.push({"email": contact["EMAIL"], "email_type": "GENERAL"});
      }
  
      if(contact["ADDRESS"]){
        let splitAdress = contact["ADDRESS"].split(" ");
        let street = "";
        let street_no = "";
  
        splitAdress.forEach((addressPart, index) => {
          if(index == splitAdress.length - 1){
            street_no = addressPart;
          } else {
            street += addressPart;
          }
        });
  
        contact.address_list = [];
        contact.address_list.push({"street": street, "street_no": street_no, "zipcode": contact["POSTALCODE"], "place": contact["LIVINGPLACE"], "country": contact["COUNTRY"]});
      }

      if(contact["LINKEDIN"] || contact["TWITTER"] || contact["FACEBOOK"] || contact["INSTAGRAM"]){
        contact.url_list = [];
        if(contact["LINKEDIN"]){
          contact.url_list.push({url: contact["LINKEDIN"], url_type: "LINKEDIN", url_list: "LINK"});
        }
        if(contact["TWITTER"]){
          contact.url_list.push({url: contact["TWITTER"], url_type: "TWITTER", url_list: "LINK"});
        }
        if(contact["FACEBOOK"]){
          contact.url_list.push({url: contact["FACEBOOK"], url_type: "FACEBOOK", url_list: "LINK"});
        }
        if(contact["INSTAGRAM"]){
          contact.url_list.push({url: contact["INSTAGRAM"], url_type: "INSTAGRAM", url_list: "LINK"});
        }
        if(contact["WEBSITE"]){
          contact.url_list.push({url: contact["WEBSITE"], url_type: "WEBSITE", url_list: "LINK"});
        }
      }

      let convertedContact;

      if(contact["FIRSTNAME"]) convertedContact.first_name = contact["FIRSTNAME"];
      if(contact["LASTNAME"]) convertedContact.last_name = contact["LASTNAME"];
      if(contact["COMPANY"]) convertedContact.company = contact["COMPANY"];
      if(contact["FUNCTION"]) convertedContact.function = contact["FUNCTION"];
      if(contact.email_list) convertedContact.email_list = contact["email_list"];
      if(contact.telephone_list) convertedContact.telephone_list = contact["telephone_list"];
      if(contact.address_list) convertedContact.address_list = contact["address_list"];
      if(contact.url_list) convertedContact.url_list = contact["url_list"];

      CSVData.push(convertedContact);
    });
    return CSVData;
  }

  public async exportContactsCSV() {
    let selectedContacts = await this.selectLocalContacts();

    let nativeContacts: any[] = [];

    if(selectedContacts.dismissed == true){
      selectedContacts = selectedContacts.contacts;
  
      for (let contact of selectedContacts) {
        //Convert to proper contact type for CSV
        let nativeContact = [];

        if(contact.first_name){
          nativeContact.push(contact.first_name);
        } else { nativeContact.push(""); }

        if(contact.last_name){
          nativeContact.push(contact.last_name);
        } else { nativeContact.push(""); }

        if(contact.company){
          nativeContact.push(contact.company);
        } else { nativeContact.push(""); }

        if(contact.function){
          nativeContact.push(contact.function);
        } else { nativeContact.push(""); }

        //email
        if(contact.email_list && contact.email_list.length > 0){
          nativeContact.push(contact.email_list[0].email);
        } else { nativeContact.push(""); }

        //phone
        if(contact.telephone_list && contact.telephone_list.length > 0){
          nativeContact.push(contact.telephone_list[0].number);
        } else { nativeContact.push(""); }

        if(contact.address_list && contact.address_list.length > 0){
          //address
          if(contact.address_list[0].street){
            nativeContact.push(contact.address_list[0].street + " " + contact.address_list[0].street_no);
          } else { nativeContact.push(""); }
          //postalcode
          if(contact.address_list[0].zipcode){
            nativeContact.push(contact.address_list[0].zipcode);
          } else { nativeContact.push(""); }
          //livingplace
          if(contact.address_list[0].place){
            nativeContact.push(contact.address_list[0].place);
          } else { nativeContact.push(""); }
          //country
          if(contact.address_list[0].country){
            nativeContact.push(contact.address_list[0].country);
          } else { nativeContact.push(""); }
        } else {
          nativeContact.push("");
          nativeContact.push("");
          nativeContact.push("");
          nativeContact.push("");
        }

        let found = ["", "", "", "", ""];

        if(contact.url_list && contact.url_list.length > 0){
          contact.url_list.forEach(url => {
            if(found[0] == "" && url.url_type == "Facebook"){
              found[0] = url.url;
            }
            if(found[1] == "" && url.url_type == "Twitter"){
              found[1] = url.url;
            }
            if(found[2] == "" && url.url_type == "Facebook"){
              found[2] = url.url;
            }
            if(found[3] == "" && url.url_type == "Instagram"){
              found[3] = url.url;
            }
            if(found[4] == "" && url.url_type == "Website"){
              found[4] = url.url;
            }
          });
          //linkedin
          nativeContact.push(found[0]);
          
          //twitter
          nativeContact.push(found[1]);
          
          //facebook
          nativeContact.push(found[2]);
  
          //instagram
          nativeContact.push(found[3]);
  
          //instagram
          nativeContact.push(found[4]);

        } else {
          nativeContact.push("");
          nativeContact.push("");
          nativeContact.push("");
          nativeContact.push("");
          nativeContact.push("");
        }

        nativeContacts.push(nativeContact);
      }
    }
    return nativeContacts;
  }

  public convertRelationData(incomingData){
    console.log("incoming data: ", incomingData);
    let relationData = [];
    incomingData.forEach(contact => {
      if(contact["phone_numbers"]){
        contact.telephone_list = [];
        contact["phone_numbers"].forEach(data => {
          contact.telephone_list.push({"number": data["number"], "number_type": data["type"]});
        });
      }
      
      if(contact["emails"]){
        contact.email_list = [];
        contact["emails"].forEach(data => {
          contact.email_list.push({"email": data["email"], "email_type": data["type"]});
        });
      }
  
      if(contact["addresses"]){
        contact.address_list = [];
        contact["addresses"].forEach(data => {    
          contact.address_list.push({"street": data["street"], "street_no": data["street_number"], "zipcode": data["zip_code"], "place": data["place"], "country": data["country"]});
        });
      }

      let convertedContact = {};

      console.log("Contact: ", contact);
      if(contact["person"]){
        convertedContact["last_name"] = "";
        if(contact["person"].first_name) convertedContact["first_name"] = contact["person"].first_name;
        if(contact["person"].middle_name) convertedContact["last_name"] += contact["person"].middle_name;
        if(contact["person"].middle_name && contact["person"].surname) convertedContact["last_name"] += " ";
        if(contact["person"].surname) convertedContact["last_name"] += contact["person"].surname;
      }
      if(contact.email_list) convertedContact["email_list"] = contact["email_list"];
      if(contact.telephone_list) convertedContact["telephone_list"] = contact["telephone_list"];
      if(contact.address_list) convertedContact["address_list"] = contact["address_list"];

      relationData.push(convertedContact);
    });
    return relationData;
  }

  public async importSingleContact(contactList, favouriteList): Promise<void> {
    GlobalService.log(`Import begins, importing a single contact`);
    let selected;
    if(this.globalService.isDesktop()) selected = {"dismissed": true, "contacts": [{
      "address_list": [],
      "avatar": "assets/icon/contacts.svg",
      "company": "",
      "email_list": [],
      "favourite": "0",
      "first_name": "Lorem",
      "function": "",
      "last_name": "Ipsum",
      "relationshipstatus": "MISCELLANEOUS",
      "telephone_list": [],
      "url_list": []
    }]};
    else selected = await this.openSelectSingleContactsPage();
    GlobalService.log(`Selected single contact: `, selected);

    if(selected && selected.dismissed == true){
      GlobalService.log(`Firing import single contact functionality`);
      this.ImportSingleContactFunctionality(selected.contacts).catch((e) =>{
        GlobalService.log("failed to import contact");
        return;
      });
    } else {
      GlobalService.log(`No contact selected`);
      ContactService.ContactsPageContactList = contactList;
      ContactService.ContactsPageFavouriteContactList = favouriteList;
      return;
    }
  }

  private async ImportSingleContactFunctionality(selectedContact){
    let contactList = await this.contactService.getAllContacts();
    console.log("contact list", contactList);
    let duplicateWarning = false;
    GlobalService.log("importing contact: ", selectedContact[0]);

    for(let contact of contactList){
      if(!contact.first_name) contact.first_name = "";
      if(!contact.last_name) contact.last_name = "";
      if(!contact.company) contact.company = "";
      if(contact.first_name == selectedContact[0].first_name){
        GlobalService.log("Same first name");
        if(contact.last_name == selectedContact[0].last_name){
          GlobalService.log("Same last name'");
          if(contact.company == selectedContact[0].company)
            duplicateWarning = true;
        }
      }
    }

    if(duplicateWarning){
      return this.singleDuplicateContact(selectedContact);
    } else {
      await this.importInChunks(selectedContact as HunterContact[]);
      if(this.selectedContact){
        ContactService.SetCurrentContact(this.selectedContact);
        ContactService.JustImportedSingle = true;
        this.navController.navigateRoot("contact-details");
        this.selectedContact = undefined;
      } else this.navController.navigateRoot("tabs/dashboard");
      return;
    }
  }

  private async openSelectSingleContactsPage(): Promise<any> {
    const modal = await this.modalController.create({
      component: SelectContactsPage,
      componentProps: {
        selectedContacts: [],
        getNativeContacts: true,
        maximum: 1
      },
    });

    await modal.present();

    return modal.onDidDismiss().then((data) => data.data);
  }

  
  private async singleDuplicateContact(selectedContact){
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("CANCEL"),
      cssClass: "cancelButton wide-button"
    });
    buttons.push({
      text: this.translateService.instant("IMPORTANYWAY"),
      cssClass: "optionButton wide-button",
      handler: async () => {
        await this.importInChunks(selectedContact as HunterContact[]);
        if(this.selectedContact){
          ContactService.SetCurrentContact(this.selectedContact);
          ContactService.JustImportedSingle = true;
          this.navController.navigateRoot("contact-details");
          this.selectedContact = undefined;
        }
        return;
      }
    });
    
    this.globalService.ionicAlert(
      buttons,
      "DUPLICATEFOUND"
    );
  }

  public convertToRelationAPI(contact){
    let relationContact = {"person": undefined};
    //Basic info

    relationContact["person"] = {};
    if(contact.first_name) relationContact.person["first_name"] = contact.first_name;
    if(contact.last_name) relationContact.person["surname"] = contact.last_name;
    if(contact.first_name) relationContact.person["full_name"] = contact.first_name;
    if(contact.last_name) relationContact.person["full_name"] += " " + contact.last_name;
    relationContact["phone_numbers"] = [];
    relationContact["emails"] = [];
    relationContact["addresses"] = [];
    
    //phone_numbers

    if(contact.telephone_list && contact.telephone_list.length > 0){
      for(let phone_number of contact.telephone_list){
        let tempObject = {"type": phone_number.number_type, "number": phone_number.number};

        relationContact["phone_numbers"].push(tempObject);
      }
    }

    //emails

    if(contact.email_list && contact.email_list.length > 0){
      for(let email_list of contact.email_list){
        let tempObject = {"type": email_list.email_type, "email": email_list.email};

        relationContact["emails"].push(tempObject);
      }
    }

    //addresses

    if(contact.address_list && contact.address_list.length > 0){
      for(let address_list of contact.address_list){
        let tempObject = {"street": address_list.street, "street_number": address_list.street_no, 
        "zip_code": address_list.zipcode, "place": address_list.place, "country": address_list.country};

        relationContact["addresses"].push(tempObject);
      }
    }

    return relationContact;
  }

  public async deleteContacts() {
    let selectedContacts = await this.selectLocalContacts();

    if(selectedContacts.dismissed == true){
      selectedContacts = selectedContacts.contacts;
      console.log("delete", selectedContacts);
      await this.contactService.deleteContactCollection(selectedContacts);
    }
  }
}
