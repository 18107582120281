import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Action from 'src/app/models/action';
import { ActionService } from 'src/app/services/action.service';
import { NoteService } from 'src/app/services/note.service';
import { NavController, AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ContactService } from "src/app/services/contact.service";
import { GlobalService } from 'src/app/services/global.service';
import { AvatarLoaderService } from 'src/app/services/avatar-loader.service';
import actionpath from 'src/app/models/actionpath';
import { AuthService } from 'src/app/services/auth.service';
import { AdministrationService } from 'src/app/services/administration.service';
import { VoipService } from 'src/app/services/voip.service';
import { ContactTabs } from 'src/app/pages/contact-details/contact-details.page';
import { ActionTabs } from 'src/app/pages/actions/actions.page';

@Component({
  selector: 'app-action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.scss'],
})
export class ActionItemComponent implements OnInit {
  @Input() public action: Action;
  @Input() public select: boolean = false;
  @Input() public detailpage: boolean = false;
  @Input() public originatedFromDetailPage: boolean = false;
  @Input() public displayAvatar: boolean = true;
  @Input() public clickable: boolean = true;
  @Input() public lines: boolean = true;
  @Input() public searching: boolean = false;
  @Input() public goal: actionpath;
  @Input() public unfinishedInGoal: boolean = false;
  @Input() public finishedInGoal: boolean = false;
  @Input() public cDetail: boolean = false;
  @Input() public actionPage: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onFinish: EventEmitter<any> = new EventEmitter();

  public detailedContacts = [];
  public active: boolean = false;
  public hideMe: boolean = false;
  public avatar = undefined;
  public dateString = "";
  public actionUserId = "";
  public undoEnabled = false;
  public finishForwardAttemptTwo = false;

  public selectedUser = undefined;
  public loadingUser: boolean = false;
  
  constructor(
    public actionService: ActionService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private navController: NavController,
    private globalService: GlobalService,
    private contactService: ContactService,
    private noteService: NoteService,
    private avatarLoader: AvatarLoaderService,
    private administrationService: AdministrationService,
    private voipService: VoipService
  ) { }

  async ngOnInit() {
    this.initializePage();
  }

  public async initializePage(){
    if(this.clickable){
      if(this.action){
        this.active = this.action && (this.action.duedate > new Date().getTime());
    
        if(this.select != false){ this.select = true; }
    
        if(this.action.type != "personal" && this.active) this.getDetails();
        if(this.action.contacts && this.action.contacts.length > 0) this.getAvatar();
        if(this.detailpage) this.getActionDetails();

        this.initializeHideMe();
        
        if(this.action.type == "personal" || !this.action.contacts || this.action.contacts.length == 0){
          this.displayAvatar = false;
        }

        if(this.action.due == 'Invalid Date')
          this.action = await this.actionService.getAction(this.action.id_action);

        this.getUser();
        this.undoEnabled = await this.enableUndoButton();
      }
    }
  }

  public async initializeHideMe(){
    if(!AdministrationService.UserData)
      await this.administrationService.getFullUserData();

    if(!this.debugMode() && AuthService.User && this.action
    && AdministrationService.UserData.id_user != this.action.id_user
    && !AdministrationService.UserData.administration
    && AdministrationService.UserData.id_user != this.action.assigned_user){
      this.hideMe = true;
    }
  }

  public getDisplayName(retrievedContact){
    return ContactService.setDisplayName(retrievedContact);
  }

  public openActionDetails() {
    console.log("clicked action", this.action.title);
    if (!this.clickable) return;

    if(this.originatedFromDetailPage){
      ActionService.SetCurrentAction(this.action);
      this.onClick.emit(this.action);
    } else {
      ActionService.SetCurrentAction(this.action);
      this.navController.navigateRoot("action-details");
    }
  }

  public async completeTask (action: Action, goal, status) {
    if(action){
      let originalStatus = this.action.status;
      await this.actionService.serviceCompleteTask(action, goal, status, originalStatus);
      this.hideMe = true;
      console.log("emit onFinish");
      await this.actionFinishReroute();
    }
  }

  public goBack() {
    this.globalService.goBack()
  }

  public getDateString () {
    if(this.dateString == ""){
      if(this.action.status < 2) this.dateString = this.globalService.getDateString(this.action.due)
      else{
        if(!this.action.finish_date) this.action.finish_date = this.action.due;
        this.dateString = this.globalService.getDateString(this.action.finish_date)
      }
    }
    else return this.dateString;
  }

  contactConctact(type: string){
    let cancelList = [];

    if(this.action.type == "email" || this.action.type == "Mailen"){ 
      let emailList = [];
      GlobalService.log("Contacts to contact", this.detailedContacts); 
      this.detailedContacts.forEach(contactDetails => {
        if(contactDetails.email_list){
          if(contactDetails.email_list[0].email != undefined && contactDetails.email_list[0].email != ""){
              emailList.push(contactDetails.email_list[0].email);
            } else {
              cancelList.push(contactDetails);
            }
          }
        });

        if(cancelList.length > 0){
          this.alertNoContactdetailsDetected(cancelList)
        } else {
          this.mailIT(emailList);
        }
      };
    
    if(this.action.type == "call" || this.action.type == "text" || this.action.type == "Bellen"){
      let phoneList = [];
      this.detailedContacts.forEach(contactDetails => {
        if(contactDetails.telephone_list){
          GlobalService.log("Contacts to contact", contactDetails.telephone_list);
          if(contactDetails.telephone_list[0].number != undefined && contactDetails.telephone_list[0].number != ""){
              phoneList.push(contactDetails.telephone_list[0].number);
            } else {
              cancelList.push(contactDetails);
            }
          }
        });

      if(cancelList.length > 0){
        this.alertNoContactdetailsDetected(cancelList)
      } else {
        this.SelectPhoneNumber(phoneList)
      }
    }
  }

  public getDetails(){
    if(this.action && this.action.contacts){
      this.action.contacts.forEach(actionContact => {
        this.contactService.getContact(actionContact.id_contact).then((retrievedContact) => {
          this.detailedContacts.push(retrievedContact);
        })
      })
    }
  }

  public getActionDetails(){
    this.actionService.getAction(this.action.id_action).then((retrievedAction) => {
      this.action = retrievedAction;
      GlobalService.log("retrieved action", retrievedAction);
    })
  }
  
  smsIT(phoneNumber: string, message: string){
    window.location.assign('sms:' + phoneNumber + "?body=" + message);
	}

	async whatsappIT(phoneNumber: string, message: string){
    GlobalService.log("Whatsapp phone number", phoneNumber);
    window.location.assign("http://api.whatsapp.com/send?phone=" + phoneNumber + "&text=" + message);
	}

  async callIT(phoneNumber: string){
    if(!AdministrationService.UserData) await this.administrationService.getFullUserData();
    if(AdministrationService.UserData.administration && AdministrationService.UserData.administration.has_voip_customer_key && AdministrationService.UserData.internal_number){
      this.askVOIPorPhone(phoneNumber);
    } else window.location.assign('tel:' + phoneNumber);
  }

  public async askVOIPorPhone(phoneNumber: string){
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("DSTNY"),
          handler: async () =>{
            await this.voipService.callThroughVOIP(AdministrationService.UserData.internal_number, phoneNumber);
            this.globalService.returnToast(this.translateService.instant("CALLINGTHROUGHDSTNY"));
            this.afterCall(true);
      }
    });
    buttons.push({
      text: this.translateService.instant("PHONE"),
          handler: () =>{
            window.location.assign('tel:' + phoneNumber)
            this.afterCall();
      }
    });
    buttons.push({
      cssClass: "cancelButton", 
      text: this.translateService.instant("CANCEL"),
      role: 'Cancel'
    })
    this.globalService.ionicAlert(
      buttons,
      "CALLTHROUGH"
    );
  }

  public async afterCall(VOIP: boolean = false){
    let buttons = []
    buttons.push({
      text: this.translateService.instant("YES"),
          handler: () =>{
            this.completeTask(this.action, this.goal, 2);
      }
    });
    buttons.push({ cssClass: "cancelButton", 
      text: this.translateService.instant("NO"),
      role: 'Cancel'
    })

    this.globalService.ionicAlert(
      buttons,
      this.translateService.instant("COMPLETETASK") + "?"
    );
  }    

  mailIT(mailList){
    let emailAdress = "";
    mailList.forEach(email => {
      emailAdress += email + ";"
    });

    emailAdress = emailAdress.substring(0, emailAdress.length - 1);

    let mailbody = this.action.description + "\r\n\r\n";
    mailbody = encodeURIComponent(mailbody);

    window.location.href = "mailto:" + emailAdress + "?subject=" + this.action.title + ", deadline: " + this.action.due + "&body=" + mailbody;
  }

  async SelectPhoneNumber(phoneList){
    //More information on how this functionality is desired is required
    let phoneNumber = phoneList[0];

    let buttons = [];
    buttons.push({
      text: this.translateService.instant("CALL"),
          handler: () =>{
        this.callIT(phoneNumber);
      }
    });
    buttons.push({
      text: this.translateService.instant("TEXTING"),
      handler: () =>
      {
        this.smsIT(phoneNumber, "message");
      }
    });
    buttons.push({
      text: this.translateService.instant("WHATSAPP"),
      handler: () =>
      {
        this.whatsappIT(phoneNumber, "message");
      }
    });
    buttons.push({ cssClass: "cancelButton", 
      text: this.translateService.instant("CANCEL"),
      role: 'Cancel'
    });

    this.globalService.ionicAlert(
      buttons,
      "HOWCONTACTWITHPERSON"
    );
	}

  async alertNoContactdetailsDetected(contact){
    let contactList = "";

    contact.forEach(individualContact => {
      contactList += individualContact.first_name + " " + individualContact.last_name + ", "
    });

    contactList = contactList.substring(0, contactList.length - 2);

    if(this.action.type == "email" || this.action.type == "Mailen"){ 
      this.noContactDetailsAlert("LACKANEMAILADDRESS", contactList);
    }
    
    if(this.action.type == "call" || this.action.type == "text" || this.action.type == "Bellen"){
      this.noContactDetailsAlert("LACKAPHONENUMBER", contactList);
    }
  }

  public async noContactDetailsAlert(field: string, contactList: any){
    let buttons = [];
    buttons.push({
      cssClass: "cancelButton", 
      text: this.translateService.instant("DISMISS"),
      role: 'Cancel'
      });
    buttons.push({
      cssClass: "cancelButton", 
      text: this.translateService.instant("DISMISS"),
      role: 'Cancel'
      });

      this.globalService.ionicAlert(
        buttons,
        "ERROR",
        this.translateService.instant(field)  + "" + contactList
      )
  }
  
  public async getAvatar () {
    if (!this.action || !this.action.contacts || !this.action.contacts[0]) return;

    if(!this.action.contacts[0].avatar || !this.action.contacts[0].avatar.includes('contacts.svg')){
      if(this.action.contacts[0].id_contact != undefined){
        this.avatarLoader.addToQueue(this.action.contacts[0]);
        this.avatar = await this.avatarLoader.getAvatar(this.action.contacts[0], true);
      }
    } else {
      this.avatar = this.action.contacts[0].avatar;
    }
  }

  public isOverdue(){
    let todayDate: Date = new Date();
    let totalDays = Math.floor(todayDate.getTime() / 86400000);
    let actionDays = Math.floor(this.action.duedate / 86400000);
    if (actionDays < totalDays){
      return true
    } else return false;
  }

  public async rescheduleAction(){
    GlobalService.IsLoading = true;
    let buttons = [];
    
    let finalAction = await this.actionService.getActionPure(this.action.id_action);
    GlobalService.log("reschedule action", finalAction);
    let curHour: number = new Date().getHours();
    let actHour: number = new Date(finalAction.due).getHours();
    let finalDate = new Date();
    console.log(actHour + " > " + curHour);
    finalDate.setHours(new Date(finalAction.due).getHours());
    finalDate.setMinutes(new Date(finalAction.due).getMinutes());
    if(actHour > curHour){
      buttons.push({
        text: this.translateService.instant("TODAY"), handler: async () => {
          await this.rescheduleTask(this.action, finalDate);
        }
      });
    }
    
    buttons.push({
      text: this.translateService.instant("TOMORROW"), handler: async () =>{
        finalDate.setDate(finalDate.getDate() + 1);
        await this.rescheduleTask(this.action, finalDate);
      }
    });

    buttons.push({
      text: this.translateService.instant("NEXTWEEK"), handler: async () =>{
        finalDate.setDate(finalDate.getDate() + 7);
        await this.rescheduleTask(this.action, finalDate);
      }
    });

    this.globalService.ionicAlert(
      buttons,
      "WHENFOLLOWUP"
    );
    GlobalService.IsLoading = false;
  }

  public getLimitedDescription(){
    if(this.action.description){
      let finalDesc = this.action.description;
      if(this.action.description.includes("\n"))
        finalDesc = finalDesc.split("\n")[0];
  
      if(finalDesc.length == 0) return " ";
      return finalDesc;
    } else return this.translateService.instant(this.action.type.toUpperCase());
  }

  async rescheduleTask(action, finalDate){
    await this.actionService.rescheduleTask(action, finalDate);
    await this.reloadAction();
    this.action = ActionService.Action;
    this.dateString = "";
  }

  async actionFinishReroute(){
    console.log("action is finished, sending user forward");
    if(this.action.contacts && this.action.contacts.length == 1){
      GlobalService.log("action finished, sending user forward. Action had one contact.");
      ContactService.SetCurrentContact(this.action.contacts[0]);
      ActionService.ResetActionPage = true;
      ContactService.RefreshContactActions = true;
      ContactService.ContactDetailPageTab = ContactTabs.Touchpoints;
      this.navController.navigateRoot("contact-details");
    }
    else if (this.action.contacts && this.action.contacts.length > 1){
      GlobalService.log("action finished, sending user forward. Action had multiple contacts.");
      ActionService.ResetActionPage = true;
      ActionService.ActionPageTab = ActionTabs.Touchpoints;
      this.navController.navigateRoot("tabs/actions");
    }
    else {
      if(!this.finishForwardAttemptTwo){
        this.action = await this.actionService.getAction(this.action.id_action);
        this.finishForwardAttemptTwo = true;
        this.actionFinishReroute();
      } else {
        GlobalService.log("action finished, sending user forward. Action had no contacts.");
        ActionService.ActionPageTab = ActionTabs.Archive;
        ActionService.ResetActionPage = true;
        this.navController.navigateRoot("tabs/actions");
      }
    }
  }

  async enableUndoButton(){
    let enable = false;
    if(this.debugMode()){
      enable = true;
    } else {
      let currentHourhour = Math.floor(new Date().getTime() / 3600000);
      let actionHour = Math.floor(new Date(this.action.finish_date).getTime() / 3600000);
      // console.log("current hour: " + currentHourhour + ". Hours since last action: " + actionHour);
      if (actionHour >= currentHourhour - 1) enable = true;
    }
    return enable;
  }

  async getUser(){
    if(!this.selectedUser){
      this.loadingUser = true;
      this.selectedUser = await this.administrationService.getUserFromAdministration(this.action.assigned_user);
      this.loadingUser = false;
    }
    return this.selectedUser;
  }

  async reloadAction(){
    await this.initializePage();
    this.action = ActionService.Action;
  }

  public getSkeletonEnabled(){
    return GlobalService.DisplaySkeleton;
  }

  public debugMode(){
    return GlobalService.DebugMode;
  }
  
  public isDesktop(){
    return this.globalService.isDesktop();
  }
}