import { Component, OnInit, ViewChild } from "@angular/core";
import Action from "src/app/models/action";
import { NavController, ModalController, AlertController, Platform } from "@ionic/angular";
import Contact from "src/app/models/contact";
import Notification from "src/app/models/notification";
import { SelectContactsPage } from "../select-contacts/select-contacts.page";
import { ActionService } from "src/app/services/action.service";
import { NotificationService } from "src/app/services/notification.service";
import { TranslateService } from '@ngx-translate/core';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalService } from 'src/app/services/global.service'
import { SelectGoalsPage } from "../select-goals/select-goals.page";
import actionpath from "src/app/models/actionpath";
import { ActionpathService } from 'src/app/services/actionpath.service';
import { AuthService } from "src/app/services/auth.service";
import { AddactionpathPage } from "../add-actionpath/add-actionpath.page";
import { AdministrationService } from 'src/app/services/administration.service';

@Component({
  selector: "app-add-action",
  templateUrl: "./add-action.page.html",
  styleUrls: ["./add-action.page.scss"],
})
export class AddActionPage implements OnInit {
  @ViewChild('titleId', {static: false}) titleInput;
  
  public isEditingExisting: boolean = false;
  public original: Action;
  public action: Action;
  public ionicForm;
  public isSubmitted: boolean = false;
  public showContactSelect: boolean = false;

  public title: string = "";
  public type: string;
  public interactionType: string;
  public id_action: number;
  public date: string = new Date().toISOString();
  public time: string = "08:56";
  public description: string;
  public selectedContacts: Contact[];
  public notifications: Notification[];
  public addToCalendar: boolean;
  public hasNotifications: boolean;
  public currentContact;
  public calendarTime: string = "15";
  public setNotifications = [];
  public originalNotifications: Notification[];
  public status = 0;
  public finalAction = undefined;
  public id_goal = "";
  public previousAction;
  public followUpAction;
  public showTimeSelection: boolean = false;
  public actionpaths: actionpath[];
  public prevAction: Action;
  public disableTypeSelection: boolean = false;
  public immediateAccept: boolean = false;
  public prevActionTime;
  public followUpData;
  public assignedUser: string = "";
  public loggedinUser;
  public checkedUserData: boolean = false;
  public userAdmin = undefined;

  private minTime: number;

  get controls() {
    return this.ionicForm.controls;
  }

  constructor(
    private navController: NavController,
    private modalController: ModalController,
    private actionService: ActionService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private calendar: Calendar,
    private platform: Platform,
    private globalService: GlobalService,
    private router: Router,
    private actionPathService: ActionpathService,
    private administrationService: AdministrationService
  ) {}

  async ngOnInit() {
    this.isSubmitted = false;

    await this.ionViewWillEnterFromInit();
  }

  ionViewWillLeaveManual () {
    ActionService.SetIsEditing(false);
    ActionService.PreviousAction = "";
  }

  async ionViewWillEnterFromInit() {
    if(!AdministrationService.UserData) await this.administrationService.getFullUserData();
    this.loggedinUser = AdministrationService.UserData;
    await this.inAdministration();
    if(AdministrationService.UserData.administration){
      this.userAdmin = AdministrationService.AdminList;
      if(!this.userAdmin) this.userAdmin = await this.administrationService.getAdministration(AdministrationService.UserData.administration.id_administration)
    }

    GlobalService.log("log admin", this.userAdmin);
    this.minTime = Date.now();
    let baseDate = new Date(Date.now());
    let baseMonth = baseDate.getMonth() + 1;
    this.date = baseDate.getFullYear() + "-" + ("0" + baseMonth).slice(-2) + "-" + ("0" + baseDate.getDate()).slice(-2);

    this.time = (0 + new Date(Date.now()).getHours().toString()).slice(-2) + ":" + (0 + new Date(Date.now()).getMinutes().toString()).slice(-2);

    this.isEditingExisting = ActionService.IsEditing;
    this.original = ActionService.Action;
    this.previousAction = ActionService.PreviousAction;
    this.selectedContacts = [];
    this.notifications = [];
    
    if (this.isEditingExisting) {
      GlobalService.IsLoading = true;
      if(this.original.type != "Personal") {
        this.original.type2 = this.original.type;
        this.original.type = "Action"
      }

      this.id_action = this.original.id_action;
      this.title = this.original.title;
      this.description = this.original.description;
      this.type = this.original.type;
      this.interactionType = this.original.type2;
      this.hasNotifications = this.original.has_notifications;
      this.status = this.original.status;
      this.previousAction = this.original.id_previous_action;
      this.followUpAction = this.original.id_follow_up_action;
      this.actionpaths = this.original.actionpaths;
      this.assignedUser = this.original.assigned_user;

      if(this.interactionType == undefined || this.interactionType == ""){ 
        this.interactionType = "email";
      }

      baseDate = new Date(this.original.duedate);
      baseMonth = baseDate.getMonth() + 1;
      this.date = baseDate.getFullYear() + "-" + ("0" + baseMonth).slice(-2) + "-" + ("0" + baseDate.getDate()).slice(-2);

      this.time = GlobalService.sliceToTwo(new Date(this.original.duedate).getHours()) + ":" 
      + GlobalService.sliceToTwo(new Date(this.original.duedate).getMinutes());

      if (this.original.contacts && this.original.contacts.length > 0) {
        for (let i = 0; i < this.original.contacts.length; i++) {
          this.actionService.getContact(this.original.contacts[i].id_contact)
            .then((contact) => {
              this.selectedContacts.push(contact);
            });
        }
      }

      this.originalNotifications = this.original.action_notifications;
      if (this.original.action_notifications && this.original.action_notifications.length > 0) {
        for (let i = 0; i < this.original.action_notifications.length; i++) {
          let timeDifference = Math.round(
            ((new Date(this.original.action_notifications[i].time).getTime()) / 60000) - 
            ((new Date(this.original.duedate).getTime()) / 60000));
          timeDifference = Math.round(((timeDifference / 60)*2)/2);

          if(timeDifference < 0){
            timeDifference = timeDifference * -1;
          }
          if(timeDifference == -0){
            timeDifference = 0.5;
          }

          this.original.action_notifications[i].time = timeDifference.toString();
          this.notifications.push(this.original.action_notifications[i]);
        }
      }
    } else {
      this.title = "";
      this.type = "Personal";
      this.interactionType = "email";
      this.hasNotifications = false;
      this.assignedUser = AdministrationService.UserData.id_user;
      this.status = ActionService.ActionStatus;
    }

    if(this.status > 1){
      this.type = "Action";
      this.enableContactSelection();
    }

    this.loadContact();

    this.enableNotifications();

    let followUpActivated: boolean = false;
    
    if(this.followUpData){
      console.log("what is followupdata", this.followUpData);
      GlobalService.IsLoading = true;
      if(this.followUpData.goal){
        this.id_goal = this.followUpData.goal;
      }
      if(this.followUpData.followUpDate){
        followUpActivated = true;
        baseDate = new Date();
        baseDate.setDate(baseDate.getDate() + this.followUpData.followUpDate)
        console.log("base date", baseDate);
        baseMonth = baseDate.getMonth() + 1;
        this.date = baseDate.getFullYear() + "-" + ("0" + baseMonth).slice(-2) + "-" + ("0" + baseDate.getDate()).slice(-2);
        console.log("new date", this.date);
      }
      if(this.followUpData.prevActionTime) this.prevActionTime = this.followUpData.prevActionTime;
      if(this.followUpData.immediateAccept) this.immediateAccept = this.followUpData.immediateAccept;
    }

    this.route.queryParams.subscribe(params => {
      if (params) {
        GlobalService.IsLoading = true;
        if(params.goal){
          this.id_goal = params.goal;
        }
        if(params.followupDate){
          followUpActivated = true;
          baseDate = new Date();
          baseDate.setDate(baseDate.getDate() + params.followupDate)
          baseMonth = baseDate.getMonth() + 1;
          this.date = baseDate.getFullYear() + "-" + ("0" + baseMonth).slice(-2) + "-" + ("0" + baseDate.getDate()).slice(-2);
        }
        if(params.prevActionTime) this.prevActionTime = params.prevActionTime;
        if(params.immediateAccept) this.immediateAccept = params.immediateAccept;
        if(params.type != undefined) this.interactionType = params.type;
      }
    });

    if(!followUpActivated && !this.isEditingExisting || this.isEditingExisting && new Date().getTime() > new Date(this.date).getTime()){
      baseDate = new Date();
      let baseHours = baseDate.getHours() + 2;
      let baseMinutes = baseDate.getMinutes();
      if(baseMinutes <= 20) baseMinutes = 0;
      if(baseMinutes > 20 && baseMinutes <= 40) baseMinutes = 30;
      if(baseMinutes > 40 && baseMinutes <= 60) baseMinutes = 60;
      if(baseMinutes == 60){
        baseHours = baseHours + 1;
        baseMinutes = 0;
      }

      if(baseHours >= 24){
        baseHours -= 24;
        baseDate.setDate(baseDate.getDate() + 1)
      }
      this.time = ("0" + baseHours).slice(-2) + ":" + ("0" + baseMinutes).slice(-2);

      baseMonth = baseDate.getMonth() + 1;
      this.date = baseDate.getFullYear() + "-" + ("0" + baseMonth).slice(-2) + "-" + ("0" + baseDate.getDate()).slice(-2);  
    }
    
    GlobalService.log("previousAction", this.previousAction);
    if(this.previousAction && !this.isEditingExisting){
      this.type = "Action";
      this.enableContactSelection();

      this.prevAction = await this.actionService.getAction(this.previousAction);
      GlobalService.log("prevaction obtained", this.prevAction);

      this.selectedContacts = this.prevAction.contacts;
      let titleNumber = 2;
      let prevTitle = this.prevAction.title;
      if(prevTitle.includes(" #") && !isNaN(parseInt(prevTitle.slice(-1)))){
        let titleHolder = prevTitle.split(" #");
        prevTitle = "";
        titleHolder.forEach(titlePart => {
          if(titlePart != titleHolder[titleHolder.length - 1])
            prevTitle += titlePart + " #";
        });
        prevTitle = prevTitle.slice(0, -2)
        titleNumber = parseInt(titleHolder[titleHolder.length - 1]) + 1;
        GlobalService.log("previous title", prevTitle);
        GlobalService.log("title number", "#" + titleNumber);
      }

      this.title = prevTitle + " #" + titleNumber;

      if(this.prevActionTime){
        this.time = GlobalService.sliceToTwo(new Date(this.prevActionTime).getHours()) + ":" 
        + GlobalService.sliceToTwo(new Date(this.prevActionTime).getMinutes());
      }
    }
    
    if (!this.isEditingExisting) {
      if(this.status == 2) { 
        baseDate = new Date();
        let baseHours = baseDate.getHours();
        let baseMinutes = baseDate.getMinutes();
        if(baseMinutes <= 20) baseMinutes = 0;
        if(baseMinutes > 20 && baseMinutes <= 40) baseMinutes = 30;
        if(baseMinutes > 40 && baseMinutes <= 60) baseMinutes = 60;
        if(baseMinutes == 60){
          baseHours = baseHours + 1;
          baseMinutes = 0;
        }
  
        if(baseHours >= 24){
          baseHours -= 24;
          baseDate.setDate(baseDate.getDate() + 1)
        }
        baseMonth = baseDate.getMonth() + 1;
        this.time = ("0" + baseHours).slice(-2) + ":" + ("0" + baseMinutes).slice(-2);

        this.date = baseDate.getFullYear() + "-" + ("0" + baseMonth).slice(-2) + "-" + ("0" + baseDate.getDate()).slice(-2);
      }

      await this.focusTitle();
    }

    if(GlobalService.DebugMode) GlobalService.log("What is time?", this.time);

    GlobalService.IsLoading = false;
    if(this.immediateAccept) {
      await this.submit();
    }
  }

  public async focusTitle(){
    while(!document.getElementById("titleId").getElementsByTagName("input")[0]) await GlobalService.sleep(100); 
    console.log("does titleId exist", document.getElementById("titleId").getElementsByTagName("input")[0]);
    document.getElementById("titleId").getElementsByTagName("input")[0].focus();
  }
  
  public getDateString (dateObj) {
    return this.globalService.getDateString(dateObj);
  }

  public updateActionDateConversion(duedate){
    GlobalService.log(duedate);
    let arr = duedate.toLocaleString().split(/[/ - :]/);
      
    if(arr[6] == "PM"){
      arr[3] = parseInt(arr[3]) + 12;
      if(parseInt(arr[3]) > 23) { arr[3]=12; }
    }

    arr[2] = arr[2].replace(",", "");
    GlobalService.log(arr);

    arr[0] = (0 + arr[0].toString()).slice(-2);
    arr[1] = (0 + arr[1].toString()).slice(-2);
    arr[3] = (0 + arr[3].toString()).slice(-2);
    arr[4] = (0 + arr[4].toString()).slice(-2);

    if(this.globalService.isIos() == true){
      duedate = arr[2] + "-" + arr[1] + "-" + arr[0] + "T" + arr[3] + ":" + arr[4];
    } else {
      duedate = arr[2] + "-" + arr[0] + "-" + arr[1] + "T" + arr[3] + ":" + arr[4];
    }
    GlobalService.log(duedate);
    return duedate;
  }

  public disableContactSelection() {
    this.showContactSelect = false;
  }

  public enableContactSelection() {
    this.showContactSelect = true;
  }

  public removeContact(contact: Contact) {
    GlobalService.log("remove contact");
    this.selectedContacts.splice(this.selectedContacts.indexOf(contact), 1);
  }

  public enableNotifications() {
    if (this.notifications.length == 0) {
      this.addNotification();
    }
  }

  public removeNotification(notification: Notification) {
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }

  public goBack(dismissed: boolean = false) {
    if(this.originalNotifications){
      this.original.action_notifications = this.originalNotifications;
    }
    ActionService.ActionStatus = 0;
    if(ActionService.FromNote || this.immediateAccept){
      ActionService.FromNote = false;
      this.ionViewWillLeaveManual();
        
      this.modalController.dismiss({
        dismissed: dismissed,
        action: this.finalAction
      });
    } else {
      this.ionViewWillLeaveManual();
      this.navController.back();
    }
  }

  public addNotification() {
    GlobalService.log("added notification to action", this.notifications);
    this.notifications.push({
      time: "0.5",
      canEdit: true
    });
  }

  public checkNotificationTimeValidity(hours: number): boolean {
    return (
      new Date(this.date).getTime() - hours * (60 * 60 * 1000) > this.minTime
    );
  }

  public async selectContacts() {
    let safelist = [];

    this.selectedContacts.forEach(element => {
      safelist.push(element);
    });
    
    const modal = await this.modalController.create({
      component: SelectContactsPage,
      componentProps: {
        selectedContacts: this.selectedContacts,
        selectAllAllowed: false,
        addToEntity: true
      },
    });

    modal.onDidDismiss().then((data) => {
      GlobalService.log(safelist);
      GlobalService.log(this.selectedContacts);
      GlobalService.log(data.data);
      if(data.data.dismissed == true){
        this.selectedContacts = this.selectedContacts;
      } else {
        this.selectedContacts = safelist;
      }
    });

    return await modal.present();
  }

  public async createActionObject(): Promise<Action> {
    let action: any = {};
    
    let newdate = new Date(this.date);
    newdate.setHours(parseInt(this.time.split(":")[0]));
    newdate.setMinutes(parseInt(this.time.split(":")[1]));
    action.due = newdate.getTime();

    action.title = GlobalService.doNotAllowSpecial(this.title);
    action.description = GlobalService.doNotAllowSpecial(this.description) || "";
    action.type = this.type;
    action.type2 = this.interactionType;
    action.contacts = [];
    action.calendar_state = this.addToCalendar;
    action.notification_state = this.hasNotifications;
    action.status = this.status;
    action.id_follow_up_action = this.followUpAction;
    action.id_previous_action = this.previousAction;
    action.actionpaths = this.actionpaths;
    action.assigned_user = this.assignedUser;

    //This should be changed when the API update launches as they will be two seperate types
    GlobalService.log(action.type);

    if(action.type == "Personal"){
      action.type2 = null;
      this.selectedContacts = [];
    }
    //This too
    if(action.type2 != null && action.type2 != ""){
      action.type = action.type2;
    }

    if (this.isEditingExisting) {
      action.id_action = this.id_action;
    }

    if (this.selectedContacts && this.selectedContacts.length) {
      for (let i = 0; i < this.selectedContacts.length; i++) {
        action.contacts.push({
          id_contact: this.selectedContacts[i].id_contact,
        });
      }
    }


    if (this.hasNotifications) {
      action.action_notifications = this.notifications;
      
      let newAction = await this.actionService.convertActionNotifications(action, this.selectedContacts, action.action_notifications)
      action = newAction;
    } else {
      action.action_notifications = [];
    }

    return action;
  }

  public validateAction(): boolean {
    if (this.isStringEmpty(this.time)) return false;
    if (this.isStringEmpty(this.date)) return false;
    if (this.isStringEmpty(this.title)) return false;
    if (this.isStringEmpty(this.type)) return false;

    if (this.type == "Action") {
      if (this.selectedContacts.length == 0) return false;
    }

    return true;
  }

  public isStringEmpty(str) {
    return !str || str.length === 0 || !str.trim();
  }

  public async submit() {
    if (!this.validateAction()){
      let buttons = [];
      buttons.push({
        text: this.translateService.instant("DISSMIS"),
        cssClass: "cancelButton"
      });
      
      this.globalService.ionicAlert(
        buttons,
        "ACTION",
        "PLEASEACTIONCREATION",
        "custom-alert",
        true,
        true
      )
      return;
    } 

    if(this.isSubmitted == false ){ 
      let action = await this.createActionObject();
      console.log("created action object", action);
      this.isSubmitted = true;

      if (this.isEditingExisting) {
        this.updateAction(action);
      } else {
        if(this.status > 1) this.pickGoalType(action);
        else this.createAction(action);
      }
    }
  }

  public async updateAction(action: Action) {
    GlobalService.IsLoading = true;
    if(this.original.action_notifications){
      for(let notification of this.original.action_notifications){
        GlobalService.log("This notification is deleted", notification);
        await this.actionService.deleteNotification(notification)
      }
      this.original.action_notifications = undefined;
    }

    let initialNotifications = this.notifications;

    if(action.action_notifications){
      for(let notification of action.action_notifications){
        GlobalService.log("This notification is created", notification);
        notification = await this.actionService.createNotification(notification, action);
      }
    }

    let res = await this.actionService.updateAction(action);
    this.finalAction = await this.actionService.finishActionUpdate(res, action, initialNotifications);
    this.goBack(true);
    this.isSubmitted = false;
  }

  async createAction(action: Action) {
    let context = this;
    let sequence: boolean = false;

    if(ActionService.PreviousAction && ActionService.PreviousAction != ""){
      sequence = true;
      action.id_previous_action = ActionService.PreviousAction
    }
    
    GlobalService.IsLoading = true;
    
    //This puts the action to the API, creating the action
    let res = await this.actionService.createAction(action);

    GlobalService.log(res);
    let fullAction = res as unknown as Action;
    
    if(fullAction.id_action){
      let fullAction = res as unknown as Action;
      fullAction.contacts = action.contacts;
      if(fullAction.contacts.length > 0){
        fullAction.contacts[0] = await this.actionService.getContact(fullAction.contacts[0].id_contact);
      }

      if(sequence){
        //To-Do: Refresh previousAction so that all data is correct
        this.actionService.removeActionFromList({"id_action": fullAction.id_previous_action});
        let previousAction = await this.actionService.getAction(fullAction.id_previous_action);
        ActionService.PushAction(previousAction);
      }
      ActionService.PushAction(fullAction);

      if(ActionService.SelectedGoals && ActionService.SelectedGoals.length > 0){
        await this.fixActionActionPaths(fullAction);
      }
      
      if(this.addToCalendar && this.platform.is('mobile')){
        GlobalService.log(`Action due: ${action.due}`);
        let dateValue: any = action.due;

        let eventDateStart = new Date(dateValue);
        let eventDateEnd = new Date(dateValue);
        eventDateEnd.setTime(eventDateEnd.getTime() + (Number(context.calendarTime)*60*1000));

        this.calendar.createEvent(action.title, null, action.description, eventDateStart, eventDateEnd).then(x => {
          GlobalService.log(`Calendar result: ${JSON.stringify(x)}`);
          this.globalService.returnToast("ADDEDTOCALENDAR");
        });
      } else if (!this.platform.is('mobile')) {
        console.warn("Platform not supported");
      }
      
      if(fullAction.action_notifications && fullAction.action_notifications.length > 0){
        for (let i = 0; i < fullAction.action_notifications.length; i++) {
          this.notificationService.scheduleNotification(fullAction.title, fullAction.action_notifications[i], fullAction);
        }
      }
      
      if(fullAction.status > 1) this.globalService.returnToast("ADDTOUCHPOINTSUCCESS");
      else this.globalService.returnToast("ADDACTIONSUCCESS");

      this.finalAction = fullAction;
      GlobalService.IsLoading = false;
      this.goBack(true);
    } else {
      this.globalService.returnToast("PLEASEACTIONCREATION");
      GlobalService.IsLoading = false;
      this.isSubmitted = false;
    }
  }

  async fixActionActionPaths(fullAction){
    for(let goal of ActionService.SelectedGoals){
      let tempPath: actionpath = await this.actionPathService.getactionpath(goal.id_action_path);
      let tempActions = [];
      if(!tempPath.actions) tempPath.actions = [];
      if(tempPath.actions){
        tempPath.actions.forEach(tempAction => {
          tempActions.push(tempAction.id_action);
        });
      }
      tempActions.push(fullAction.id_action);
      if(fullAction.id_previous_action || fullAction.id_follow_up_action){
        let sequenceList = await this.actionService.prepareSequence(fullAction.id_action);
        sequenceList.forEach(actionInSequence => {
          tempActions.push(actionInSequence);
        });
      }

      tempPath.actions = [...new Set(tempActions)];
      await this.actionPathService.updateactionpath(tempPath);
      await this.actionPathService.reloadPage();
      GlobalService.log("updated!");
    }
  }

  public async checkCalendarPermission(){
    this.addToCalendar = true
    let permission: boolean = await this.calendar.hasReadWritePermission();
    GlobalService.log("calendar permission", permission);
    if (!permission) {
      await this.calendar.requestReadWritePermission();
      permission = await this.calendar.hasReadWritePermission();
      if(!permission) this.addToCalendar = false;
    }
  }
  
  public loadContact(){
    this.selectedContacts = [];
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.currentContact = params.id_contact;
        if(this.currentContact != undefined){
          this.type = "Action";
          GlobalService.log(this.currentContact);
          this.disableTypeSelection = true;
          this.actionService.getContact(this.currentContact).then((res) => {
            this.selectedContacts.push(res);
          });
          GlobalService.log(this.selectedContacts);
        }
      }
    });
  }

  onEnter(event) {
    this.globalService.onEnter(event)
  }

  public setDisplayName(contact){
    if(!contact) return "?";
    
    if(contact.first_name != undefined && contact.first_name != "" && contact.first_name != "?"){
      if(contact.last_name != undefined && contact.last_name != "" && contact.last_name != "?") {
        return contact.first_name + " " + contact.last_name;
      } else {
        return contact.first_name;
      }
    } else if(contact.last_name != undefined && contact.last_name != "" && contact.last_name != "?"){
      return contact.last_name;
    } else {
      if(contact.company) return contact.company;
      else if(contact.function) return contact.function;
      else return "?";
    }
  }

  public loggit(){
    GlobalService.log(this.date);
  }

  isDesktop(){
    return this.globalService.isDesktop();
  }

  isIos(){
		if(this.platform.is("ios") == true) return true;
    return false;
  }

  public async selectGoals(){
    ActionService.SelectedGoals = [];
    let safelist = [];

    if(ActionService.SelectedGoals && ActionService.SelectedGoals.length > 0){
      ActionService.SelectedGoals.forEach(element => {
        safelist.push(element);
      });
    }
    
    const modal = await this.modalController.create({
      component: SelectGoalsPage,
      componentProps: {
        selectedGoals: ActionService.SelectedGoals,
      },
    });

    modal.present();

    return modal.onDidDismiss().then((data) => {
      if(data.data.dismissed == true){
        ActionService.SelectedGoals = data.data.goals;
      } else {
        ActionService.SelectedGoals = safelist;
      }

      return data.data
    });
  }

  public async pickGoalType(action){
    let allGoals = await this.actionPathService.getAllactionpaths();
    let goalCounter: number = 0;
    for(let goal of allGoals) if(goal.status != "CLOSED") goalCounter++;
    
    if(!this.id_goal && goalCounter > 0){
      let buttons = [];
      buttons.push({
        text: this.translateService.instant("YES"),
        cssClass: 'delete-yes first-yes-no',
        handler: async () => {
          if(allGoals.length == 1){
            ActionService.SelectedGoals = [{"id_action_path": allGoals[0].id_action_path}];
            if (this.isEditingExisting) {
              this.updateAction(action);
            } else {
              this.createAction(action);
            }
          } else this.selectGoalType(action);
        }
      });
      buttons.push({
        text: this.translateService.instant("NO"),
        cssClass: 'delete-no second-yes-no',
        handler: async () => {
          if (this.isEditingExisting) {
            this.updateAction(action);
          } else {
            this.createAction(action);
          }
        }
      });

      let header = this.translateService.instant("ADDACTIONTOGOAL");
      if(this.status > 1) header = this.translateService.instant("ADDTOUCHPOINTTOGOAL");
      
      this.globalService.ionicAlert(
        buttons,
        header
      )
    } else {
      if(this.id_goal) ActionService.SelectedGoals = [{"id_action_path": parseInt(this.id_goal)}];

      if (this.isEditingExisting) {
        this.updateAction(action);
      } else {
        this.createAction(action);
      }
    }
  }

  private async openNewGoalsPage(): Promise<any> {
    ActionpathService.FromAction = true;
    const modal = await this.modalController.create({
      component: AddactionpathPage,
    });

    modal.cssClass = "fullscreen-modal";
    

    await modal.present();

    return modal.onDidDismiss().then((data) => {
      if(data.data.dismissed == true && data.data.goal){
        ActionService.SelectedGoals = [data.data.goal];
      } else {
        ActionService.SelectedGoals = [];
      }

      return data.data
    });
  }

  private async selectGoalType(action){
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("ADDACTIONPATH"),
      cssClass: "optionButton",
      handler: async () => {
        ActionpathService.FromAction = true;
        let selected = await this.openNewGoalsPage();
        GlobalService.log(selected);
        if(selected.dismissed){          
          if (this.isEditingExisting) {
            this.updateAction(action);
          } else {
            this.createAction(action);
          }
        } else {
          this.isSubmitted = false;
        }
      }
    });
    buttons.push({
      text: this.translateService.instant("SELECTGOALS"),
      cssClass: "optionButton",
      handler: async () => {
        let selected = await this.selectGoals();
        GlobalService.log(selected);
        if(selected.dismissed){          
          if (this.isEditingExisting) {
            this.updateAction(action);
          } else {
            this.createAction(action);
          }
        } else {
          this.isSubmitted = false;
        }
      }
    });
    buttons.push({
      text: this.translateService.instant("CANCEL"),
      cssClass: "cancelButton",
      handler: () => {
        this.isSubmitted = false;
      }
    });
    
    this.globalService.ionicAlert(
      buttons,
      this.translateService.instant("ACTION") + " " + this.translateService.instant("GOAL").toLowerCase()
    )
  }
  
  public toggleTime(){
    if(!this.showTimeSelection) this.showTimeSelection = true;
    else this.showTimeSelection = false;
  }

  debugTap(){
    GlobalService.debugTap();
  }

  public debugMode(){
    return GlobalService.DebugMode;
  }

  public async unsavedData(){
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("YES"),
      handler: async () => { 
        this.goBack();
      },
    });
    buttons.push({
      text: this.translateService.instant("NO"),
    });
    
    this.globalService.ionicAlert(
      buttons,
      "UNSAVEDDATA",
      "DOYOUWANTTOLEAVEWITHOUTSAVING",
      "yes-no-alert"
    );
  }

  async inAdministration(){
    if(!AdministrationService.UserData && !this.checkedUserData) await this.administrationService.getFullUserData();
    this.checkedUserData = true;
    if(AdministrationService.UserData.administration && AdministrationService.UserData.administration.id_administration){
      return true;
    }
    else return false;
  }
}
